export default {
    path: '/',
    name: '2fa-reauthentication-layout',
    component: require('@/views/2fa-reauthentication/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_CUSTOMER_HOST
    },
    children: [{
            path: '/2fa-reauthentication/:token',
            name: '2fa-reauthentication-page',
            component: () =>
                import ( /* webpackChunkName: "2fa-reauthentication" */ '@/views/2fa-reauthentication/Index.vue'),
            meta: {
                title: '2FA Reauthentication Page',
                requireAuth: false,                
            },
        }
    ]
}