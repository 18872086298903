<template>
	<select
		class="form-select"
		:class="{
			className,
		}"
		:name="name"
		:id="getId"
		:value="modelValue"
		@change="$emit('update:modelValue', $event.target.value)"
		autocomplete="none"
	>
		<option v-if="show_placeholder" value="">{{ placeholder }}</option>
		<template v-for="(item, key) in options" :key="key">
			<option :selected="modelValue == key" :value="key">{{ item }}</option>
		</template>
	</select>
</template>

<script>
export default {
	name: "FormSelect",
	props: {
		className: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			default: "Select option",
		},
		name: {
			type: String,
			default: "",
		},
		id: {
			type: String,
			default: "",
		},
		show_placeholder: {
			type: Boolean,
			default: true,
		},
		modelValue: String,
		options: Object,
	},

	computed: {
		getId() {
			if (this.id == "") {
				return this.name;
			}
			return this.id;
		},
	},
};
</script>
