export default [
    // Tax Agent Dashboard
    {
        path: '/myirc/tax-agent-centre/dashboard',
        name: 'tax-agent-dashboard',
        component: () =>
            import ( /* webpackChunkName: "tax-agent-dashboard" */ '@/views/myirc/tax-agent-centre/dashboard/Index.vue'),
        meta: {
            title: 'Tax Agent Dashboard',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    },
    // Activity
    {
        path: '/myirc/tax-agent-centre/activities',
        name: 'my-secondary-account-activity',
        component: () =>
            import ( /* webpackChunkName: "my-secondary-account-activity" */ '@/views/myirc/tax-agent-centre/activities/Index.vue'),
        meta: {
            title: 'Secondary Account Activity',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    },
    {
        path: '/myirc/tax-agent-centre/activities/:unique_id',
        name: 'view-secondary-account-activity',
        component: () =>
            import ( /* webpackChunkName: "my-secondary-account-activity-detail" */ '@/views/myirc/tax-agent-centre/activities/Detail.vue'),
        meta: {
            title: 'View Activity',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    },

]
.concat(require('./my-taxpayers').default)
.concat(require('./secondary-accounts-activities').default)
.concat(require('./secondary-account-notification-settings').default)
.concat(require('./my-secondary-accounts').default);