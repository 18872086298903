export default {
    path: '/',
    name: 'search-layout',
    component: require('@/views/search/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_CUSTOMER_HOST
    },
    children: [{
        path: '/search/:search?',
        name: 'search',
        component: () =>
            import ( /* webpackChunkName: "search" */ '@/views/search/Index.vue'),
        meta: {
            title: 'Search',
            requireAuth: false,
        },
    }]
}