<template>
  <div class="wrapper">
    <div class="left_part">
      <SideBarMenu
        manu_active="Update Appointed Nominee"
        :myIRCMenuClass="myIRCMenuClass"
        :overlayClick="overlayClick"
      ></SideBarMenu>
    </div>

    <div class="inner_page">
      <!-- Breadcrumb -->
      <Breadcrumb
        :myIRCMenuBtnClick="myIRCMenuBtnClick"
        :breadcrumb_arr="breadcrumb_arr"
      ></Breadcrumb>

      <SideBarMobileMenu
        manu_active="Update Appointed Nominee"
      ></SideBarMobileMenu>

      <div class="content">
        <h1 class="mb-3">Update my Appointed Nominee</h1>
        <h3>You can update your appointed nominee any time from this page.</h3>

        <form id="update-my-billing-information-form" @submit.prevent="submit">
          <h2 class="mb-3">Your Appointed Nominee Information:</h2>

          <div class="form-row row">
            <div class="form-group col-md-6 col-12 mb-4">
              <div class="form-floating">
                <form-input
                  placeholder="Nominee given name(s)"
                  name="appointed_nominee_given_name"
                  ref="appointed_nominee_given_name"
                  v-model="formData.appointed_nominee_given_name"
                ></form-input>
                <label for="appointed_nominee_given_name"
                  >Nominee given name(s)</label
                >
              </div>
            </div>
            <div class="form-group col-md-6 col-12 mb-4">
              <div class="form-floating">
                <form-input
                  placeholder="Nominee surname"
                  name="appointed_nominee_surname"
                  ref="appointed_nominee_surname"
                  v-model="formData.appointed_nominee_surname"
                ></form-input>
                <label for="appointed_nominee_surname">Nominee surname</label>
              </div>
            </div>
          </div>
          <div class="form-row row">
            <div class="form-group col-md-6 col-12 mb-4">
              <div
                class="form-floating"
                :class="{
                  error: validate.formData.appointed_nominee_email.$error,
                }"
              >
                <form-input
                  placeholder="Nominee email address"
                  name="appointed_nominee_email"
                  ref="appointed_nominee_email"
                  v-model="formData.appointed_nominee_email"
                ></form-input>
                <label for="appointed_nominee_email"
                  >Nominee email address</label
                >
              </div>
              <form-element-error
                :errors="validate.formData.appointed_nominee_email.$errors"
              ></form-element-error>
            </div>
            <div class="form-group col-md-6 col-12 mb-4">
              <div class="form-floating">
                <form-input
                  placeholder="Nominee phone number"
                  name="appointed_nominee_phone_number"
                  ref="appointed_nominee_phone_number"
                  v-model="formData.appointed_nominee_phone_number"
                ></form-input>
                <label for="appointed_nominee_phone_number"
                  >Nominee phone number</label
                >
              </div>
            </div>
          </div>

          <p>
            You have the option of setting an Appointed Nominee who will be
            nominated as the preferred contact point for you myIRC account. If
            you wish to receive all correspondence, please leave this section
            blank.
          </p>
          <div class="d-flex gap-3 mb-4 justify-content-end">
            <form-button
              :loading="loading"
              buttonType="submit"
              buttonClass="irc-btn btn-mardi-gras d-flex"
              buttonTitle="Update my Appointed Nominee"
              buttonId="submit_btn"
              buttonIconClass="fal fa-save right-icon align-self-center"
            >
            </form-button>
          </div>

          <form-validation-error-msg
            :errors="formErrors"
            :formValidationMsg="showFormValidationMsg"
          ></form-validation-error-msg>
        </form>

        <FooterNotice></FooterNotice>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import breadcrumb from "@/mixins/breadcrumb.js";
import { required, helpers, email } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "Update Appointed Nominee",
  mixins: [breadcrumb],
  components: {
    /*SideBarMenu: require("@/views/myirc/includes/SideBarMenu.vue").default,
    Breadcrumb: require("@/views/myirc/includes/Breadcrumb.vue").default,
    FooterNotice: require("@/views/myirc/includes/FooterNotice.vue").default,*/
    SideBarMenu: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "sidebar-menu" */ "@/views/myirc/includes/SideBarMenu.vue"
      )
    ),
    Breadcrumb: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "breadcrumb" */ "@/views/myirc/includes/Breadcrumb.vue"
      )
    ),
    FooterNotice: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "footer-notice" */ "@/views/myirc/includes/FooterNotice.vue"
      )
    ),
  },

  data() {
    return {
      formData: {
        appointed_nominee_given_name: "",
        appointed_nominee_surname: "",
        appointed_nominee_phone_number: "",
        appointed_nominee_email: "",
      },
      loading: false,
    };
  },

  setup() {
    return {
      validate: useVuelidate(),
    };
  },

  computed: {
    form_validation_rules() {
      let validationObj = {
        formData: {
          appointed_nominee_email: {
            email: helpers.withMessage("Invalid email address", email),
          },
        },
      };

      return validationObj;
    },
  },

  validations() {
    return this.form_validation_rules;
  },

  mounted() {
    this.setBreadcrumb([
      {
        title: "myIRC",
        link: "dashboard",
      },
      {
        title: "Update my Appointed Nominee",
        link: "",
      },
    ]);
    this.getBillingInformation();
  },

  methods: {
    getBillingInformation() {
      this.axios
        .post("/api/myirc/my-account/appointed-nominee")
        .then((response) => {
          this.formData = response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            this.$helpers.catchDataApiAxiosErrors(error, this);
          }
        });
    },

    submit() {
      this.validate.$touch();
      if (this.validate.$invalid) {
        this.showFormValidationMsg = true;
        this.$helpers.scrollToFirstErrorField(
          this.validate.$errors[0].$property
        );
      } else {
        this.loading = true;
        this.showFormValidationMsg = false;
        this.axios
          .post("/api/myirc/my-account/appointed-nominee/update", this.formData)
          .then((response) => {
            this.toast_notification(response.data);
            this.loading = false;
          })
          .catch((error) => {
            if (error.response) {
              this.$helpers.catchFormApiAxiosErrors(error, this);
              this.loading = false;
            }
          });
      }
    },
  },
};
</script>
