<template>
  <input
    :type="type"
    class="form-control"
    :class="{
      className,
    }"
    :placeholder="placeholder"
    :name="name"
    :id="id"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    v-money3="config"
    autocomplete="none"
  />
</template>

<script>
import { Money3Component } from "v-money3";

export default {
  name: "FormInput",
  components: { money3: Money3Component },
  props: {
    type: {
      type: String,
      default: "text",
    },
    className: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    modelValue: Number,
  },

  data() {
    return {
      config: {
        thousands: "",
        decimal: ".",
        precision: 2,
        disableNegative: true,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
      },
    };
  },
};
</script>
