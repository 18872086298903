<template>
  <div id="main">
    <!-- Css -->
    <Css></Css>

    <vue-progress-bar></vue-progress-bar>
    <SiteWideNotice></SiteWideNotice>
    <!-- Header -->
    <Header></Header>
    <!-- Section -->
    <section>
      <router-view />
    </section>
    <!-- Footer -->
    <Footer></Footer>
  </div>
</template>
<script>
import { getCurrentInstance } from "vue";
import { defineAsyncComponent } from "vue";
export default {
  name: "Support Centre Layout",
  components: {
    Header: defineAsyncComponent(() =>
      import(/* webpackChunkName: "header" */ "@/layouts/app/header/Index.vue")
    ),
    Footer: defineAsyncComponent(() =>
      import(/* webpackChunkName: "footer" */ "@/layouts/app/footer/Index.vue")
    ),
    SiteWideNotice: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "site-wide-notice" */ "@/views/includes/SiteWideNotice.vue"
      )
    ),
    Css: defineAsyncComponent(() =>
      import(/* webpackChunkName: "search-css" */ "@/layouts/app/header/Css.vue")
    ),
    /*Header: require("@/layouts/app/header/Index.vue").default,
    Footer: require("@/layouts/app/footer/Index.vue").default,
    SiteWideNotice: require("@/views/includes/SiteWideNotice.vue").default,*/
  },
  setup() {
    const internalInstance = getCurrentInstance();

    //  [App.vue specific] When App.vue is first loaded start the progress bar
    internalInstance.appContext.config.globalProperties.$Progress.start();
    setTimeout(() => {
      internalInstance.appContext.config.globalProperties.$Progress.finish();
    }, 3500);
  },
};
</script>
