export default [{
    path: '/myirc/payments/my-payment-history',
    name: 'my-payment-history',
    component: () =>
        import ( /* webpackChunkName: "my-payment-history" */ '@/views/myirc/payments/payment-history/Index.vue'),
    meta: {
        title: 'My Payment History',
        requireAuth: true,
        checkMyIRCMaintenanceMode: true,
    },
}, {
    path: '/myirc/payments/my-payment-history/success/:unique_id',
    name: 'view-success-payment',
    component: () =>
        import ( /* webpackChunkName: "view-success-payment" */ '@/views/myirc/payments/payment-history/payment-success/Show.vue'),
    meta: {
        title: 'View Payment',
        requireAuth: true,
        checkMyIRCMaintenanceMode: true,
    },
}, {
    path: '/myirc/payments/my-payment-history/success/:unique_id/transaction',
    name: 'view-success-payment-transaction',
    component: () =>
        import ( /* webpackChunkName: "view-success-payment-transaction" */ '@/views/myirc/payments/payment-history/payment-success/Transaction.vue'),
    meta: {
        title: 'View Payment Transaction',
        requireAuth: true,
        checkMyIRCMaintenanceMode: true,
    },
}, {
    path: '/myirc/payments/my-payment-history/failed/:unique_id',
    name: 'view-failed-payment',
    component: () =>
        import ( /* webpackChunkName: "view-failed-payment" */ '@/views/myirc/payments/payment-history/payment-failed/Show.vue'),
    meta: {
        title: 'View Payment',
        requireAuth: true,
        checkMyIRCMaintenanceMode: true,
    },
}, {
    path: '/myirc/payments/my-payment-history/failed/:unique_id/transaction',
    name: 'view-failed-payment-transaction',
    component: () =>
        import ( /* webpackChunkName: "view-failed-payment-transaction" */ '@/views/myirc/payments/payment-history/payment-failed/Transaction.vue'),
    meta: {
        title: 'View Failed Payment Transaction',
        requireAuth: true,
        checkMyIRCMaintenanceMode: true,
    },
}];