import { helpers } from "@vuelidate/validators";

// validations_email: this.$validations.email,
const email = helpers.withMessage(
    'Please check your entry. Allowed characters are: a-z , A-Z , 0-9, @ , . (dot), - (hyphen), _ (underscore).',
    helpers.regex(/^[0-9a-zA-Z\(\).@&_-]*$/)
);

// validations_name: this.$validations.name,
const name = helpers.withMessage(
    'Please check your entry. Allowed characters are: a-z , A-Z , 0-9, @, . (dot), &, - (hyphen), _ (underscore), ‘ (apostrophe), ( ) brackets.',
    helpers.regex(/^[0-9a-zA-Z\(\) \'.@&_-]*$/)
);

// validations_title: this.$validations.title,
const title = helpers.withMessage(
    'Please check your entry. Allowed characters are: a-z , A-Z , 0-9, @, ?, . (dot), &, - (hyphen), _ (underscore), ‘ (apostrophe), ( ) brackets.',
    helpers.regex(/^[0-9a-zA-Z\(\) \'.@?&_-]*$/)
);

// validations_phone: this.$validations.phone,
const phone = helpers.withMessage(
    'Please check your entry. Allowed characters are: 0-9, ( , ) , +, - (hyphen).',
    helpers.regex(/^[0-9\(\) +-]*$/)
);

// validations_address: this.$validations.address,
const address = helpers.withMessage(
    'Please check your entry. Allowed characters are: a-z , A-Z , 0-9, @, . (dot), &, - (hyphen), _ (underscore), ‘ (apostrophe), ( ) brackets.',
    helpers.regex(/^[0-9a-zA-Z\(\) \'.,@&_-]*$/)
);

// validations_amount: this.$validations.amount,
const amount = helpers.withMessage(
    'Please check your entry. Allowed characters are: 0-9, . (dot)',
    helpers.regex(/^[0-9.]*$/)
);

// validations_only_number: this.$validations.only_number,
const only_number = helpers.withMessage(
    'Please check your entry. Allowed characters are: 0-9',
    helpers.regex(/^[0-9]*$/)
);

// validations_zipcode: this.$validations.zipcode,
const zipcode = helpers.withMessage(
    'Please check your entry. Allowed characters are: 0-9 , a-z , A-Z',
    helpers.regex(/^[0-9a-zA-Z]*$/)
);

// validations_other: this.$validations.other,
const other = helpers.withMessage(
    'Please check your entry. Allowed characters are: + , 0-9, a-z, A-Z, @,  . (dot), &,  - (hyphen),  _ (underscore),  , (comma),  : (colon), ; (semicolon),  " (double quote), ‘ (apostrophe), ?,  !,  %,  $, %, #,  /, * (asterisk), ( ) brackets.',
    helpers.regex(/^[0-9a-zA-Z /\r|/\n\(\)\'\"\+#\/@&._,:;?!%$-]*$/)
    // \(\) #@.&-_,:;"?!%$
);


// validations_url: this.$validations.url,
const url = helpers.withMessage(
    'Please check your entry. Allowed characters are: + , 0-9, a-z, A-Z, . (dot), &,  - (hyphen),  _ (underscore),   : (colon),  ?,  %,  #,  /',
    helpers.regex(/^[0-9a-zA-Z/\+#&._:?%$-]*$/)
    // \(\) #@.&-_,:;"?!%$
);

// validations_password: this.$validations.password,
const password = helpers.withMessage(
    'Please check your entry. Allowed characters are: 0-9, a-z, A-Z, #, ?, !, @, $, %, ^, &, * (asterisk) , _ (underscore), - (hyphen), " (double quote), / (slash), : (colon), ; (semicolon), ( ) brackets.',
    helpers.regex(/^[0-9a-zA-Z\\ !\"#$%&\'\(\)*\+,./:;<=>?@\[\]^_`{|}~-]*$/)
);


export default {
    email,
    name,
    phone,
    address,
    amount,
    only_number,
    zipcode,
    other,
    password,
    title,
    url,
}