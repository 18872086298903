<template>
    <!-- Css -->
    <Css></Css>

    <Header class="unsubscribe-header"></Header>
    <!-- Section -->
    <section>
        <router-view />
    </section>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
    name: "Unsubscribe Layout",
    components: {
        Header: defineAsyncComponent(() =>
            import(
        /* webpackChunkName: "error-header" */ "@/layouts/error/Header.vue"
            )
        ),
        Css: defineAsyncComponent(() =>
            import(/* webpackChunkName: "unsubscribe-css" */ "@/layouts/app/header/Css.vue")
        ),
        /*Header: require("@/layouts/error/Header.vue").default,
        Footer: require("@/layouts/error/Footer.vue").default,*/
    },
};
</script>


<style>
.unsubscribe-header {
    padding: 80px 0;
    padding-bottom: 40px;
}

header h2 {
    margin: 0 0 65px 0;
    padding: 0;
    font-size: 38px;
    line-height: 45px;
    color: #000000;
    font-weight: normal;
    font-family: 'Montserrat', sans-serif;
}

.error_logo {
    display: inline-block;
    margin: 0;
    padding: 50px !important;
    border: 2px solid #000;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    background: #fff;
    z-index: 999;
    position: relative;
}

.logo-container {
    position: relative;
}

.logo-container::after {
    display: inline-block;
    content: "";
    border-top: 2px solid black;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
}

.error_logo img {
    width: 100%;
}

.error_menu {
    margin: 0;
    padding: 0;
    background: #F0F0F0;
}

.error_menu a {
    display: inline-block;
    padding: 18px 19px;
    font-weight: 500;
    font-size: 23px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #1E1E1E;
    text-decoration: none;
    position: relative;
    border-left: 19px solid #fff;
    border-right: 19px solid #fff;
    min-height: 64px;
}

.error_sec {
    width: 100%;
    float: left;
    margin: 0;
    padding: 10px 0 60px 0;
    text-align: center;
}

.error_sec .container {
    max-width: 770px;
}

.error_sec h2 {
    margin: 0 0 65px 0;
    padding: 0;
    font-size: 38px;
    line-height: 45px;
    color: #000000;
    font-weight: normal;
    font-family: 'Montserrat', sans-serif;
}

.error_sec p {
    margin: 0 0 65px 0;
    padding: 0;
    font-size: 24px;
    line-height: 45px;
    color: #000000;
    font-weight: 300;
}

.error_sec p a {
    text-decoration-line: underline;
    color: #000000;
}

.error_sec p a:hover {
    color: #A8201A;
}

.error_sec .irc-btn {
    border-radius: 4px;
    display: inline-block;
    margin-bottom: 65px;
}

.error_sec .irc-btn i {
    margin-right: 13px;
}

.error_sec .rcr_links li a span {
    padding: 6px 10px;
}

.error_footer.footer_detail {
    padding: 26px 0;
}

.error_footer.footer_detail .ft_block {
    max-width: 261px;
    text-align: center;
    padding: 0 0 34px 0 !important;
    float: none;
    display: block;
    margin: 0 auto;
    border-bottom: 1px solid #C0E0DE;
}

.error_footer.footer_detail p {
    margin-bottom: 15px;
}

.error_footer.footer_detail p strong {
    display: block;
    color: #F3B700;
    margin: 0;
    padding: 0;
}

.error_footer .c_list {
    text-align: center;
    margin-top: 15px;
    display: inline-block;
    float: none;
}

.error_footer .copyright {
    text-align: center;
    padding: 23px 0 0 0;
    float: none;
    display: block;
    margin: 0 auto;
    border: none;
}

@media (max-width:1300px) {
    .error_logo {
        padding: 15px 0;
    }
}

@media (max-width: 991px) {
    .error_sec {
        padding: 40px 0 30px 0;
    }

    .error_sec h2 {
        font-size: 28px;
        line-height: normal;
        margin-bottom: 30px;
    }

    .error_sec p {
        margin: 0 0 30px 0;
        font-size: 18px;
        line-height: 34px;
    }

    .error_sec .irc-btn {
        margin-bottom: 40px;
    }
}

@media (max-width:600px) {
    .error_sec h2 {
        font-size: 26px;
    }

    .error_sec p {
        font-size: 16px;
        line-height: 28px;
    }
}
</style>
