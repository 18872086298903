export default {
    methods: {
        toast_notification(Obj) {
            var msg = Obj.message;
            if (typeof Obj.title !== 'undefined') {
                msg = '<span class="toast-notification-title">' + Obj.title + '</span><br/>' + Obj.message;
            }
            var newObj = {
                message: msg || 'Default message',
                type: Obj.type || 'success',
                position: Obj.position || 'top-right',
                duration: Obj.duration || 3000,
            }
            this.$toast.open(newObj);
        },

        toast_information(Obj) {

            var msg = Obj.message;
            if (typeof Obj.title !== 'undefined') {
                msg = '<span class="toast-notification-title">' + Obj.title + '</span><br/>' + Obj.message;
            }

            var newObj = {
                message: msg || 'Default message',
                type: Obj.type || 'success',
                position: Obj.position || 'bottom-right',
                duration: Obj.duration || 3000,
            }
            this.$toast.open(newObj);
        },

        toast_notification_500() {
            this.toast_notification({
                message: 'Internal server error, Please try again!',
                type: 'error',
                title: 'Error',
            });
        },

        toast_notification_505() {
            this.toast_notification({
                message: 'You can not access this page or section',
                type: 'error',
                title: 'Error',
            });
            this.$router.go(-1);
        }
    }
}