export default [{
    path: '/myirc/tax-agent-centre/secondary-account-settings',
    name: 'secondary-account-settings',
    component: () =>
        import ( /* webpackChunkName: "secondary-account-settings" */ '@/views/myirc/tax-agent-centre/secondary-account-notification-settings/Index.vue'),
    meta: {
        title: 'Secondary account notification settings',
        requireAuth: true,
        checkMyIRCMaintenanceMode: true,
    },
}];