export default [{
        path: '/myirc/support-centre',
        name: 'support-centre',
        component: () =>
            import ( /* webpackChunkName: "support-centre" */ '@/views/myirc/support-centre/list/Index.vue'),
        meta: {
            title: 'Manage My Tickets',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    },
    {
        path: '/myirc/support-centre/submit-a-new-ticket',
        name: 'submit-a-new-ticket',
        component: () =>
            import ( /* webpackChunkName: "support-centre-submit-a-new-ticket" */ '@/views/myirc/support-centre/create/Index.vue'),
        meta: {
            title: 'Submit a New Ticket',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            permission: 'manage_support_ticket'
        },
    },
    // Enquiry
    {
        path: '/myirc/support-centre/submit-a-new-ticket/enquiry/:unique_id?',
        name: 'submit-enquiry',
        component: () =>
            import ( /* webpackChunkName: "support-centre-submit-enquiry" */ '@/views/myirc/support-centre/create/enquiry/Index.vue'),
        meta: {
            title: 'Submit Enquiry',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            permission: 'manage_support_ticket'
        },
    }, {
        path: '/myirc/support-centre/enquiry/:unique_id',
        name: 'view-enquiry',
        component: () =>
            import ( /* webpackChunkName: "support-centre-view-enquiry" */ '@/views/myirc/support-centre/show/enquiry/Index.vue'),
        meta: {
            title: 'View Enquiry',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            permission: 'manage_support_ticket'
        },
    },
    // Feedback
    {
        path: '/myirc/support-centre/submit-a-new-ticket/feedback',
        name: 'submit-feedback',
        component: () =>
            import ( /* webpackChunkName: "support-centre-submit-feedback" */ '@/views/myirc/support-centre/create/feedback/Index.vue'),
        meta: {
            title: 'Submit Feedback',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    },
    {
        path: '/myirc/support-centre/feedback/:unique_id',
        name: 'view-feedback',
        component: () =>
            import ( /* webpackChunkName: "support-centre-view-feedback" */ '@/views/myirc/support-centre/show/feedback/Index.vue'),
        meta: {
            title: 'View Feedback',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            permission: 'manage_support_ticket'
        },
    },

    // Complaint
    {
        path: '/myirc/support-centre/submit-a-new-ticket/complaint',
        name: 'submit-complaint',
        component: () =>
            import ( /* webpackChunkName: "support-centre-submit-complaint" */ '@/views/myirc/support-centre/create/complaint/Index.vue'),
        meta: {
            title: 'Submit Complaint',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            permission: 'manage_support_ticket'
        },
    },
    {
        path: '/myirc/support-centre/complaint/:unique_id',
        name: 'view-complaint',
        component: () =>
            import ( /* webpackChunkName: "support-centre-view-complaint" */ '@/views/myirc/support-centre/show/complaint/Index.vue'),
        meta: {
            title: 'View Complaint',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            permission: 'manage_support_ticket'
        },
    },


    // Fraud Report
    {
        path: '/myirc/support-centre/submit-a-new-ticket/fraud-report',
        name: 'submit-fraud-report',
        component: () =>
            import ( /* webpackChunkName: "support-centre-submit-fraud-report" */ '@/views/myirc/support-centre/create/fraud-report/Index.vue'),
        meta: {
            title: 'Submit Fraud Report',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            permission: 'manage_support_ticket'
        },
    },
    {
        path: '/myirc/support-centre/fraud-report/:unique_id',
        name: 'view-fraud-report',
        component: () =>
            import ( /* webpackChunkName: "support-centre-view-fraud-report" */ '@/views/myirc/support-centre/show/fraud-report/Index.vue'),
        meta: {
            title: 'View Fraud Report',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            permission: 'manage_support_ticket'
        },
    },

    // Help Guide

    {
        path: '/myirc/support-centre/help-guide',
        name: 'help-guide',
        component: () =>
            import ( /* webpackChunkName: "support-centre-help-guide" */ '@/views/myirc/support-centre/help-guide/Index.vue'),
        meta: {
            title: 'MyIRC Help Guide',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    },
    {
        path: '/myirc/support-centre/help-guide/:slug',
        name: 'view-help-guide',
        component: () =>
            import ( /* webpackChunkName: "support-centre-view-help-guide" */ '@/views/myirc/support-centre/help-guide/Show.vue'),
        meta: {
            title: 'MyIRC Help Guide',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    }
];