<template>
	<button
		class="btn irc-btn btn-mardi-gras d-flex d-none"
		data-bs-toggle="modal"
		data-bs-target=".logout_model"
		ref="logout_model_btn"
	>
		Open Logout Model<i
			class="fal fa-shield-check right-icon align-self-center"
		></i>
	</button>
	<div
		class="modal fade logout_model"
		id="logout_model"
		tabindex="-1"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header border-0">
					<button
						type="button"
						class="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
						ref="close_model_btn"
					></button>
				</div>
				<div class="modal-body text-center">
					<img src="/images/good-feedback-1.png" />
					<h4 class="text-center mt-4">
						<strong
							>You’re about to be logged out of your myIRC account due to
							inactivity</strong
						>
					</h4>
					<div class="text-cenre">
						<p class="mt-4 fs-1">
							<strong>{{ idle_counter }}</strong>
						</p>
					</div>
				</div>
				<div class="modal-footer d-flex justify-content-between border-0">
					<button
						type="button"
						class="btn irc-btn btn-polished-pine d-flex"
						id="stay_signed_in"
						@click="resetTimer"
					>
						Stay signed in
					</button>
					<button
						type="button"
						class="btn irc-btn btn-red d-flex"
						id="Ive_finished_logout"
						@click="logout"
					>
						I’ve finished, logout
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Logout Popup",
	props: {
		idle_counter: Number,
		resetTimer: Function,
	},
	methods: {
		logout() {
			this.$refs.close_model_btn.click();
			this.$router.push({ name: "logout" });
		},
	},
};
</script>
