export default [{
    path: '/myirc/tax-agent-centre/my-secondary-accounts',
    name: 'my-secondary-accounts',
    component: () =>
        import ( /* webpackChunkName: "my-secondary-accounts" */ '@/views/myirc/tax-agent-centre/my-secondary-accounts/Index.vue'),
    meta: {
        title: 'Manage my secondary account',
        requireAuth: true,
        checkMyIRCMaintenanceMode: true,
    },
}, {
    path: '/myirc/tax-agent-centre/my-secondary-accounts/add',
    name: 'add-my-secondary-account',
    component: () =>
        import ( /* webpackChunkName: "add-my-secondary-account" */ '@/views/myirc/tax-agent-centre/my-secondary-accounts/create/Index.vue'),
    meta: {
        title: 'Add my secondary account',
        requireAuth: true,
        checkMyIRCMaintenanceMode: true,
    },
}, {
    path: '/myirc/tax-agent-centre/my-secondary-accounts/:unique_id/view',
    name: 'view-my-secondary-account',
    component: () =>
        import ( /* webpackChunkName: "view-my-secondary-account" */ '@/views/myirc/tax-agent-centre/my-secondary-accounts/show/Index.vue'),
    meta: {
        title: 'View my secondary account',
        requireAuth: true,
        checkMyIRCMaintenanceMode: true,
    },
}, {
    path: '/myirc/tax-agent-centre/my-secondary-accounts/:unique_id/manage-settings',
    name: 'permission-my-secondary-settings',
    component: () =>
        import ( /* webpackChunkName: "permission-my-secondary-settings" */ '@/views/myirc/tax-agent-centre/my-secondary-accounts/manage-settings/Index.vue'),
    meta: {
        title: 'Manage secondary account settings',
        requireAuth: true,
        checkMyIRCMaintenanceMode: true,
    },
}, {
    path: '/myirc/tax-agent-centre/my-secondary-accounts/:unique_id/manage-assigned-taxpayers',
    name: 'my-secondary-account-assigned-taxpayers',
    component: () =>
        import ( /* webpackChunkName: "my-secondary-account-assigned-taxpayers" */ '@/views/myirc/tax-agent-centre/my-secondary-accounts/manage-assigned-taxpayers/Index.vue'),
    meta: {
        title: 'Manage Assigned Taxpayers',
        requireAuth: true,
        checkMyIRCMaintenanceMode: true,
    },
}];