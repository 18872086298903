export default [{
        path: '/myirc/manage-primary-account/update-my-billing-information',
        name: 'manage-primary-account-update-my-billing-information',
        component: () =>
            import ( /* webpackChunkName: "manage-primary-account-update-my-billing-information" */ '@/views/myirc/manage-primary-account/UpdateBillingInformation.vue'),
        meta: {
            title: 'Update my billing information',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    },
     {
        path: '/myirc/manage-primary-account/my-tax-agent-information',
        name: 'manage-primary-account-my-tax-agent-information',
        component: () =>
            import ( /* webpackChunkName: "manage-primary-account-my-tax-agent-information" */ '@/views/myirc/manage-primary-account/MyTaxAgentInformation.vue'),
        meta: {
            title: 'My Tax Agent information',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    }, {
        path: '/myirc/manage-primary-account/my-account-setting',
        name: 'manage-primary-account-my-account-setting',
        component: () =>
            import ( /* webpackChunkName: "my-account-setting" */ '@/views/myirc/manage-primary-account/account-settings/Index.vue'),
        meta: {
            title: 'My Account Setting',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    }, {
        path: '/myirc/manage-primary-account/notification-settings',
        name: 'manage-primary-account-notification-settings',
        component: () =>
            import ( /* webpackChunkName: "manage-primary-account-notification-settings" */ '@/views/myirc/manage-primary-account/notification-settings/Index.vue'),
        meta: {
            title: 'Notification Settings',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    }
];