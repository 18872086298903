<template>
  <a
    content="Copied"
    v-tippy="{ trigger: 'click', onShown: showTippy }"
    href="javascript:;"
    ref="copyButtonRef"
    id="kt_share_earn_link_copy_button"
    class="fw-bolder py-1 text-muted"
    :class="extra_class"
    v-clipboard="clipboardValue"
  >
    <i class="fal fa-copy" :class="{ color, font_class }"></i>
  </a>
</template>

<script>
export default {
  name: "Copy",
  data() {
    return {
      test: true,
    };
  },
  props: {
    color: {
      type: String,
    },
    extra_class: {
      type: String,
    },
    font_class: {
      type: String,
      default: "fs-3",
    },
    clipboardValue: String,
  },

  methods: {
    showTippy(el) {
      setTimeout(() => {
        el.hide();
      }, 1000);
    },
  },
};
</script>
