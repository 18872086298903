export default [{
        path: '/myirc/my-lodgement',
        name: 'my-lodgement',
        component: () =>
            import ( /* webpackChunkName: "my-lodgement" */ '@/views/myirc/my-lodgements/Index.vue'),
        meta: {
            title: 'Manage my lodgement',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkLodgementMaintenanceMode: true,
        },
    }, {
        path: '/myirc/my-lodgement/past',
        name: 'my-past-lodgement',
        component: () =>
            import ( /* webpackChunkName: "my-past-lodgement" */ '@/views/myirc/my-lodgements/past/Index.vue'),
        meta: {
            title: 'Manage my past lodgement',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkLodgementMaintenanceMode: true,
            permission: 'manage_lodgement'
        },
    }, {
        path: '/myirc/my-lodgement/add-new',
        name: 'add-a-new-lodgement',
        component: () =>
            import ( /* webpackChunkName: "add-a-new-lodgement" */ '@/views/myirc/my-lodgements/create/Index.vue'),
        meta: {
            title: 'Add a new lodgement',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkLodgementMaintenanceMode: true,
            permission: 'manage_lodgement'
        },
    },
    {
        path: '/myirc/my-lodgement/add-new/:taxpayer_id',
        name: 'add-a-new-lodgement-with-taxpayer',
        component: () =>
            import ( /* webpackChunkName: "add-a-new-lodgement-with-taxpayer" */ '@/views/myirc/my-lodgements/create/Index.vue'),
        meta: {
            title: 'Add a new lodgement',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkLodgementMaintenanceMode: true,
            permission: 'manage_lodgement'
        },
    },
    {
        path: '/myirc/my-lodgement/add-new/success/:unique_id',
        name: 'add-a-new-lodgement-success',
        component: () =>
            import ( /* webpackChunkName: "add-a-new-lodgement-success" */ '@/views/myirc/my-lodgements/create/Success.vue'),
        meta: {
            title: 'Success',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkLodgementMaintenanceMode: true,
            permission: 'manage_lodgement'
        },
    },
    {
        path: '/myirc/my-lodgement/view/:unique_id',
        name: 'view-my-lodgement',
        component: () =>
            import ( /* webpackChunkName: "view-my-lodgement" */ '@/views/myirc/my-lodgements/show/Index.vue'),
        meta: {
            title: 'View My Lodgement',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkLodgementMaintenanceMode: true,
            permission: 'manage_lodgement'
        },
    },

    // Lodgement Maintenance Mode Page
    {
        path: '/my-irc/my-lodgement/maintenance-mode',
        name: 'lodgement-maintenance-mode-page',
        component: () =>
            import ( /* webpackChunkName: "lodgement-maintenance-mode-page" */ '@/views/myirc/my-lodgements/maintenance-mode/Index.vue'),
        meta: {
            title: 'Lodgement Maintenance Mode',
        },
    },
];