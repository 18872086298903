export default [{
    path: '/myirc/payments/payment-basket',
    name: 'payment-basket',
    component: () =>
        import ( /* webpackChunkName: "payment-basket" */ '@/views/myirc/payments/payment-basket/Index.vue'),
    meta: {
        title: 'My payment basket',
        requireAuth: true,
        checkMyIRCMaintenanceMode: true,
        checkPaymentMaintenanceMode: true,
        permission: 'make_a_payment'
    },
}];