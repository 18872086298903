export default {
    path: '/',
    name: 'auth-layout',
    component: require('@/views/auth/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_CUSTOMER_HOST
    },
    children: [{
            path: '/login/:type?',
            name: 'login',
            component: () =>
                import ( /* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
            meta: {
                title: 'Login',
                allowGuestOnly: true,
                checkMyIRCMaintenanceMode: true,
            },
        },
        {
            path: '/2fa',
            name: '2fa',
            component: () =>
                import ( /* webpackChunkName: "2fa" */ '@/views/auth/2fa.vue'),
            meta: {
                title: 'Two factor Authentication',
                requireAuth: true,
                allowNon2FAOnly: true
            }
        }, {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () =>
                import ( /* webpackChunkName: "forgot-password" */ '@/views/auth/ForgotPassword.vue'),
            meta: {
                title: 'Forgot Password',
                allowGuestOnly: true,
                checkMyIRCMaintenanceMode: true,
            },
        }, {
            path: '/reset-password/:token',
            name: 'reset-password',
            component: () =>
                import ( /* webpackChunkName: "reset-password" */ '@/views/auth/ResetPassword.vue'),
            meta: {
                title: 'Reset Password',
                requireAuth: false,
                checkMyIRCMaintenanceMode: true,
            },
        },
        {
            path: '/resend-email-verification/:token',
            name: 'resend-email-verification',
            component: () =>
                import ( /* webpackChunkName: "resend-email-verification" */ '@/views/auth/ResendEmailVerification.vue'),
            meta: {
                title: 'Resend Email Verification',
                requireAuth: false,
                checkMyIRCMaintenanceMode: true,
            },
        },
        {
            path: '/logout',
            name: 'logout',
            component: require('@/views/auth/Logout.vue').default,
            component: () =>
                import ( /* webpackChunkName: "logout" */ '@/views/auth/Logout.vue'),
            meta: {
                title: 'Logout',
                requireAuth: false,
                checkMyIRCMaintenanceMode: true,
            },
        },


        {
            path: '/admin-access',
            name: 'admin-access',
            component: () =>
                import ( /* webpackChunkName: "admin-access" */ '@/views/auth/WebsiteAccessWithToken.vue'),
            meta: {
                title: 'Website Access With Token',
                requireAuth: false,
                checkMyIRCMaintenanceMode: false,
            },
        },


        // My IRC Maintenance Mode Page
        {
            path: '/my-irc/maintenance-mode',
            name: 'my-irc-maintenance-mode-page',
            component: () =>
                import ( /* webpackChunkName: "my-irc-maintenance-mode-page" */ '@/views/auth/maintenance-mode/Index.vue'),
            meta: {
                title: 'Maintenance Mode',
            },
        },


    ]
}