export default {
    path: '/',
    name: 'myirc-layout',
    component: require('@/views/myirc/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_CUSTOMER_HOST
    },
    children: [{
            path: '/myirc/dashboard',
            name: 'dashboard',
            component: () =>
                import ( /* webpackChunkName: "dashboard" */ '@/views/myirc/dashboard/Index.vue'),
            meta: {
                title: 'Dashboard',
                requireAuth: true,
                checkMyIRCMaintenanceMode: true,
            },
        }]
        .concat(require('./identity-verification').default)
        .concat(require('./tax-agent-centre/my-taxpayers').default)
        .concat(require('./my-account').default)
        .concat(require('./manage-primary-account').default)
        .concat(require('./manage-my-secondary-account').default)
        .concat(require('./payments').default)
        .concat(require('./my-lodgements').default)
        .concat(require('./support-centre').default)
        .concat(require('./tax-agent-centre').default)
}