<template>
<router-link @click="btn_click">
    <i class="left-icon spinner-border spinner-border-sm align-self-center" v-if="btn_loading"></i>
    <slot name="icon" v-if="!btn_loading"></slot>
    <slot name="text"></slot>
</router-link>
</template>

<script>
export default {
    name: "Button-Link",
    data() {
        return {
            btn_loading: false,
        };
    },

    methods: {
        btn_click() {
            this.btn_loading = true;
        }
    }
};
</script>
