export default {
    path: '/',
    name: 'home-layout',
    component: require('@/views/home/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_CUSTOMER_HOST
    },
    children: [{
        path: '/',
        name: 'home-page',
        component: () =>
            import ( /* webpackChunkName: "home-page" */ '@/views/home/Index.vue'),
        meta: {
            title: 'Home Page',
            requireAuth: false,
        },
    }]
}