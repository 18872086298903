<template>
  <div id="main">
    <!-- Css -->
    <Css></Css>

    <vue-progress-bar></vue-progress-bar>
    <SiteWideNotice></SiteWideNotice>
    <!-- Header -->
    <Header></Header>
    <!-- Section -->
    <section>
      <router-view />
    </section>
    <!-- Footer -->
    <Footer></Footer>
  </div>
  <LogoutPopup ref="logout_model" :resetTimer="resetTimer" :idle_counter="idle_counter"></LogoutPopup>
</template>
<script>
import { defineAsyncComponent } from "vue";
import { getCurrentInstance } from "vue";
export default {
  name: "MyIRC Layout",
  components: {
    Header: defineAsyncComponent(() =>
      import(/* webpackChunkName: "header" */ "@/layouts/app/header/Index.vue")
    ),
    Footer: defineAsyncComponent(() =>
      import(/* webpackChunkName: "footer" */ "@/layouts/app/footer/Index.vue")
    ),
    SiteWideNotice: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "site-wide-notice" */ "@/views/includes/SiteWideNotice.vue"
      )
    ),
    Css: defineAsyncComponent(() =>
      import(/* webpackChunkName: "myirc-css" */ "@/layouts/app/header/Css.vue")
    ),
    /*Header: require("@/layouts/app/header/Index.vue").default,
    Footer: require("@/layouts/app/footer/Index.vue").default,
    SiteWideNotice: require("@/views/includes/SiteWideNotice.vue").default,*/
    LogoutPopup: require("@/views/myirc/includes/LogoutPopup.vue").default,
  },
  data() {
    return {
      idle_interval: null,
      idle_counter: 60,
      time: 0,
    };
  },
  setup() {
    const internalInstance = getCurrentInstance();

    //  [App.vue specific] When App.vue is first loaded start the progress bar
    internalInstance.appContext.config.globalProperties.$Progress.start();
    setTimeout(() => {
      internalInstance.appContext.config.globalProperties.$Progress.finish();
    }, 3500);
  },
  mounted() {
    this.inactivityTime();
  },
  unmounted() {
    this.idle_interval = null;
    this.idle_counter = 60;
    this.time = 0;
  },
  watch: {
    $route(to, from) {
      this.resetTimer();
    },
  },
  methods: {
    inactivityTime() {
      window.onload = this.resetTimer();
      document.onmousemove = this.resetTimer();
      document.onkeypress = this.resetTimer();
    },
    resetTimer() {
      this.$refs.logout_model.$refs.close_model_btn.click();
      this.idle_counter = 60;
      clearTimeout(this.idle_interval);
      clearTimeout(this.time);

      this.time = setTimeout(() => {
        if (this.$store.getters["user/isAuthenticated"]) {
          this.showIdleModal();
        }
      }, 1000 * 60 * 20);
    },
    showIdleModal() {
      // Step 1: Open Modal
      this.logoutModelOpen();
      // Step 2: Run Counter
      this.idle_interval = setInterval(() => {
        this.idle_counter--;

        if (this.idle_counter <= 0) {
          // Perform Logout
          this.$refs.logout_model.$refs.close_model_btn.click();
          clearTimeout(this.idle_interval);
          clearTimeout(this.time);
          this.$router.push({ name: "logout" });
        }
      }, 1000);
    },
    logoutModelOpen() {
      this.$refs.logout_model.$refs.logout_model_btn.click();
    },
  },
};
</script>
