export default {
    path: '/',
    name: 'payment_categories-layout',
    component: require('@/views/payment_categories/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_CUSTOMER_HOST
    },
    children: [{
        path: '/making-a-payment-online',
        name: 'making-a-payment-online',
        component: () =>
            import ( /* webpackChunkName: "making-a-payment-online" */ '@/views/payment_categories/Index.vue'),
        meta: {
            title: 'Making a payment online',
            requireAuth: false,
        },

    }, {
        path: '/making-a-payment-online/:slug',
        name: 'making-a-payment-online-product',
        component: () =>
            import ( /* webpackChunkName: "making-a-payment-online-product" */ '@/views/products/Index.vue'),
        meta: {
            title: 'Making a payment online',
            requireAuth: false,
        },

    }]
}