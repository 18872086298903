export default {
    path: '/',
    name: 'key-tax-dates-layout',
    component: require('@/views/key-tax-dates/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_CUSTOMER_HOST
    },
    children: [{
        path: '/key-tax-dates',
        name: 'key-tax-dates',
        component: () =>
            import ( /* webpackChunkName: "key-tax-dates" */ '@/views/key-tax-dates/Index.vue'),
        meta: {
            title: 'Key Tax Dates',
            requireAuth: false,
        },
    }]
}