export default {
    path: '/',
    name: 'sitempa-layout',
    component: require('@/views/sitemap/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_CUSTOMER_HOST
    },
    children: [{
        path: '/sitemap',
        name: 'sitemap',
        component: () =>
            import ( /* webpackChunkName: "sitemap" */ '@/views/sitemap/Index.vue'),
        meta: {
            title: 'Sitemap',
            requireAuth: false,
        },
    }]
}