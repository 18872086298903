// Copy Element
import Copy from './copy/Copy.vue';

// Form Element
import FormInput from './form-element/FormInput.vue';
import FormCurrencyInput from './form-element/FormCurrencyInput.vue';
import FormTextArea from './form-element/FormTextArea.vue';
import FormSelect from './form-element/FormSelect.vue';
import FormSelect2 from './form-element/FormSelect2.vue';
import FormInputFile from './form-element/FormInputFile.vue';
import FormInputFileAddMore from './form-element/FormInputFileAddMore.vue';
import FormButton from './form-element/FormButton.vue';
import SearchButton from './form-element/SearchButton.vue';
import RouterLinkButton from './form-element/RouterLinkButton.vue';

// Form Element Errors
import FormElementError from './form-element-errors/FormElementError.vue'
import ElementError from './form-element-errors/ElementError.vue'
import FormValidationErrorMsg from './form-element-errors/FormValidationErrorMsg.vue'

// Other
import ButtonLink from './other/ButtonLink.vue'

export default app => {

    // Copy
    app.component('Copy', Copy);

    // Form Element
    app.component('form-input', FormInput);
    app.component('form-currency-input', FormCurrencyInput);
    app.component('form-textarea', FormTextArea);
    app.component('form-select', FormSelect);
    app.component('form-select2', FormSelect2);
    app.component('form-input-file', FormInputFile);
    app.component('form-input-file-add-more', FormInputFileAddMore);
    app.component('form-button', FormButton);
    app.component('router-link-button', RouterLinkButton);

    // Form Element Errors
    app.component('form-element-error', FormElementError);
    app.component('form-validation-error-msg', FormValidationErrorMsg);
    app.component('search-button', SearchButton);

    // Other
    app.component('button-link', ButtonLink);
    

}