
import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;

const state = () => {
    return {
        customer_login_id: null,
        customer_item: null,
        auth_token: null,
        is_authenticated: false,
        is_2fa_authenticated: false,
        global_2fa: false,
        is_secondary_account: false,
        permissionArr: null,
        website_access_token: null,
    }
}

// getters
const getters = {
    isAuthenticated(state) {
        return Boolean(state.is_authenticated);
    },

    getIs2faAuthenticated(state) {
        return Boolean(state.is_2fa_authenticated);
    },

    getUserItem(state) {
        return state.customer_item
    },

    getRequireGlobal2fa(state) {
        return state.global_2fa;
    },

    getRequire2fa(state) {
        return state.require_2fa;
    },

    isSecondaryAccount(state) {
        return state.is_secondary_account;
    },

    secondaryAccountPermissions(state) {
        return state.permissionArr;
    },

    getWebsiteAccessToken(state) {
        return state.website_access_token;
    }
}

// actions
const actions = {
    setUserFromSession(context) {

        // Require 2FA
        let require_2fa_temp = ls.get('require_2fa');
        if (typeof require_2fa_temp != 'undefined') {
            context.commit('setRequire2fa', {
                require_2fa: ls.get('require_2fa'),
            });
        }


        // User Item
        let customer_item = ls.get('customer_item');
        if (customer_item != null) {

            context.commit('setUser', {
                customer_item: JSON.parse(ls.get('customer_item')),
                auth_token: ls.get('auth_token'),
                customer_login_id: ls.get('customer_login_id'),
                global_2fa: ls.get('global_2fa'),
                is_secondary_account: eval(ls.get('is_secondary_account')),
                permissionArr: JSON.parse(ls.get('permissionArr')),
            });


            var is_2fa_authenticated = ls.get('is_2fa_authenticated');
            if (typeof is_2fa_authenticated != 'undefined' && is_2fa_authenticated !== null) {
                context.commit('set2faAuthentication', {
                    status: Boolean(ls.get('is_2fa_authenticated')),
                });
            }
        }

        context.commit('setWebsiteAccessToken', {
            website_access_token: ls.get('website_access_token')
        });
    }
}

// mutations
const mutations = {
    setUser(state, payload) {
        state.customer_item = payload.customer_item;
        state.auth_token = payload.auth_token;
        state.is_authenticated = true;
        state.global_2fa = Boolean(payload.global_2fa);
        state.customer_login_id = Boolean(payload.customer_login_id);
        state.permissionArr = payload.permissionArr;
        state.is_secondary_account = Boolean(payload.is_secondary_account);

        // Set Session
        ls.set('customer_item', JSON.stringify(payload.customer_item));
        ls.set('auth_token', payload.auth_token);
        ls.set('global_2fa', eval(payload.global_2fa));
        ls.set('is_secondary_account', eval(payload.is_secondary_account));
        ls.set('customer_login_id', payload.customer_login_id);
        ls.set('permissionArr', JSON.stringify(payload.permissionArr));
    },

    setCustomerItem(state, payload) {
        state.customer_item = payload.customer_item;
        ls.set('customer_item', JSON.stringify(payload.customer_item));
    },

    setRequire2fa(state, payload) {
        state.require_2fa = payload.require_2fa;
        ls.set('require_2fa', payload.require_2fa);
    },
    set2faAuthentication(state, payload) {
        state.is_2fa_authenticated = Boolean(payload.status);
        ls.set('is_2fa_authenticated', eval(payload.status));
    },
    logout(state) {
        state.customer_item = null;
        state.auth_token = null;
        state.global_2fa = false;
        state.is_authenticated = false;
        state.is_2fa_authenticated = false;
        state.is_secondary_account = false;
        state.customer_login_id = null;
        state.permissionArr = null;

        // Remove Session
        ls.remove('customer_item');
        ls.remove('auth_token');
        ls.remove('global_2fa');
        ls.remove('customer_login_id');
        ls.remove('session_id');
        ls.remove('is_authenticated');
        ls.remove('is_2fa_authenticated');
        ls.remove('is_secondary_account');
        ls.remove('login_expire_at');
        ls.remove('last_action_at');
        ls.remove('permissionArr');
    },

    updateLoginExpiry() {
        const now = new Date();
        let allow_mins = parseInt(process.env.VUE_APP_LOGIN_EXPIRY) * 60 * 1000;
        ls.set('login_expire_at', now.getTime() + allow_mins);
        ls.set('last_action_at', now.getTime());
    },

    setWebsiteAccessToken(state, payload) {
        state.website_access_token = payload.website_access_token;
        ls.set('website_access_token', payload.website_access_token);
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}