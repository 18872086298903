<template>
	<div class="error_msg">
		<div class="error_inn">
			<i class="fal fa-exclamation-triangle"></i>
			<div v-for="(error, index) in errors" :key="index">
				<template
					v-if="!show_first_error_only || (show_first_error_only && index == 0)"
				>
					{{ error.$message }}
				</template>
			</div>
			<div v-for="(error, index) in element_error" :key="index">
				<span v-html="error"></span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "FormElementError",
	props: {
		errors: Object,
		element_error: Array,
		show_first_error_only: {
			default: false,
		},
	},
};
</script>
