export default {
    path: '/',
    name: 'support-centre-layout',
    component: require('@/views/support-centre/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_CUSTOMER_HOST
    },
    children: [{
            path: '/support-centre',
            name: 'non-register-support-centre',
            component: () =>
                import ( /* webpackChunkName: "non-registered-support-centre" */ '@/views/support-centre/Index.vue'),
            meta: {
                title: 'Support Centre',
                requireAuth: false,
            },
        },
        {
            path: '/support-centre/enquiry/:unique_id?',
            name: 'non-register-submit-enquiry',
            component: () =>
                import ( /* webpackChunkName: "non-register-submit-enquiry" */ '@/views/support-centre/enquiry/Index.vue'),
            meta: {
                title: 'Submit a New Ticket',
                requireAuth: false,
            },
        },
        {
            path: '/support-centre/feedback',
            name: 'non-register-submit-feedback',
            component: () =>
                import ( /* webpackChunkName: "non-register-submit-feedback" */ '@/views/support-centre/feedback/Index.vue'),
            meta: {
                title: 'Submit Feedback',
                requireAuth: false,
            },
        },
        {
            path: '/support-centre/complaint',
            name: 'non-register-submit-complaint',
            component: () =>
                import ( /* webpackChunkName: "non-register-submit-complaint" */ '@/views/support-centre/complaint/Index.vue'),
            meta: {
                title: 'Submit Complaint',
                requireAuth: false,
            },
        },
        {
            path: '/support-centre/fraud-report',
            name: 'non-register-submit-fraud-report',
            component: () =>
                import ( /* webpackChunkName: "non-register-submit-fraud-report" */ '@/views/support-centre/fraud-report/Index.vue'),
            meta: {
                title: 'Submit Fraud Report',
                requireAuth: false,
            },
        },
    ]
}