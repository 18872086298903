<template>
	<input type="file" class="form-control input-file" :class="{
		className,
		'is-invalid': element_error,
	}" :name="name" :id="id" :accept="accept" ref="file_upload" aria-describedby="inputGroupFileAddon05"
		aria-label="Upload" @change="selectFile" :multiple="multiple" @click="resetImageUploader" />
	<span v-if="selected_file != ''">{{ selected_file }}</span>
	<span v-if="selected_file == ''">{{ placeholder }}</span>
	<button class="btn" type="button" id="inputGroupFileAddon05" @click="chooseFile()">
		Select file
	</button>
</template>


<script>
export default {
	name: "FormInputFileAddMore",
	props: {
		className: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
		name: {
			type: String,
			default: "",
		},
		id: {
			type: String,
			default: "",
		},
		accept: {
			type: String,
			default: "*",
		},
		selected_file: {
			type: String,
			default: "*",
		},
		data_model: {
			type: Object,
			default: {}
		},
		placeholder: {
			type: String,
			default: 'Please select file'
		},
		updateModelFunc: Function,
		indexVal: Number,
		element_error: Boolean,
		modelValue: String,
		multiple: Boolean,
	},
	data() {
		return {
			file: {},
		};
	},
	mounted() {
		this.file = this.data_model;
	},
	methods: {
		chooseFile() {
			this.$refs.file_upload.click();
		},

		selectFile(e) {
			console.log('here selectFile');
			this.file = {};

			let file = e.target.files[0];
			if (typeof (file) != 'undefined') {
				this.file.document_file = file;
				this.file.document_name = file.name;
				this.file.document_size = file.size;

				this.updateModelFunc(this.indexVal, this.file);
			} else {
				this.file = {};
				this.updateModelFunc(this.indexVal, this.file);
			}

		},
		resetImageUploader() {
			this.$refs.file_upload.value = '';
		}
	},
};
</script>
