<template>
  <router-view />
</template>


<script>
export default {
  name: "App",
  data() {
    return {
      get_ip_counter: 0,
      route_name:"",
    };
  },
  async mounted() {
    await this.$store.dispatch("user/setUserFromSession");
    this.getLoginCustomerInformation();
    this.getIpAddress();
    //this.getIPFromAmazon();
  },

  watch: {
    "$route.path": {
      handler(oldUrl, newUrl) {
        this.route_name = this.$route.name;        
      },
    },
  },  
  
  methods: {
    getLoginCustomerInformation() {
      var isAuthenticated = this.$store.getters["user/isAuthenticated"];
      if (isAuthenticated) {
        this.axios
          .post("/api/myirc/customer/get-login-customer-information")
          .then((response) => {
            this.$store.commit("user/setCustomerItem", response.data);
          })
          .catch((error) => {
            if (error.response) {
              this.$helpers.catchDataApiAxiosErrors(error, this);
            }
          });
      }
    },
    setIp(ip) {
      this.axios.interceptors.request.use(
        function (config) {
          config.headers.ip = ip;
          return config;
        },
        null,
        { synchronous: true }
      );
    },

    async getIpAddress() {
      if (this.get_ip_counter <= 3) {
        await fetch("https://jsonip.com", { mode: "cors" })
          .then((resp) => resp.json())
          .then((resp) => {
            this.setIp(resp.ip);
          })
          .catch((error) => {
            this.get_ip_counter++;
            this.getIpAddress();
          });
      } else {
        this.getIPFromAmazon();
      }
    },

    async getIPFromAmazon() {
      await fetch("https://checkip.amazonaws.com/", { mode: "cors" })
        .then((res) => {
          this.setIp(data);
        })
        .then((data) => {
          this.setIp(data);
        })
        .catch((data) => {
          this.setIp(data);
        });
    },
  },
};
</script>
