export default {
    path: '/',
    name: 'register-layout',
    component: require('@/views/register/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_CUSTOMER_HOST
    },
    children: [{
            path: '/register',
            name: 'register-page',
            component: () =>
                import ( /* webpackChunkName: "register" */ '@/views/register/Index.vue'),
            meta: {
                title: 'Register Page',
                requireAuth: false,
                checkNewRegistrationMaintenanceMode: true,
            },
        },
        {
            path: '/register/individual',
            name: 'individual-register-page',
            component: () =>
                import ( /* webpackChunkName: "individual-register" */ '@/views/register/individual/Index.vue'),
            meta: {
                title: 'Individual Register Page',
                requireAuth: false,
                checkNewRegistrationMaintenanceMode: true,
            },
        },
        {
            path: '/register/tax-agent',
            name: 'tax-agent-register-page',
            component: () =>
                import ( /* webpackChunkName: "tax-agent-register" */ '@/views/register/tax-agent/Index.vue'),
            meta: {
                title: 'Tax Agent Register',
                requireAuth: false,
                checkNewRegistrationMaintenanceMode: true,
            },
        },
        {
            path: '/register/partnership',
            name: 'partnership-register-page',
            component: () =>
                import ( /* webpackChunkName: "partnership-register" */ '@/views/register/partnership/Index.vue'),
            meta: {
                title: 'Partnership Register',
                requireAuth: false,
                checkNewRegistrationMaintenanceMode: true,
            },
        },
        {
            path: '/register/company',
            name: 'company-register-page',
            component: () =>
                import ( /* webpackChunkName: "company-register" */ '@/views/register/company/Index.vue'),
            meta: {
                title: 'Company Register',
                requireAuth: false,
                checkNewRegistrationMaintenanceMode: true,
            },
        },
        {
            path: '/register/maintenance-mode',
            name: 'register-maintenance-mode-page',
            component: () =>
                import ( /* webpackChunkName: "register-maintenance-mode" */ '@/views/register/maintenance-mode/Index.vue'),
            meta: {
                title: 'Maintenance Mode',
            },
        },
        {
            path: '/email-verification/:token',
            name: 'email-verification-page',
            component: () =>
                import ( /* webpackChunkName: "email-verification" */ '@/views/register/email-verification/Index.vue'),
            meta: {
                title: 'Email Verification',
                requireAuth: false,
            },
        },
    ]
}