export default {
    path: '/',
    name: 'error-layout',
    component: require('@/views/error-page/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_ADMIN_HOST
    },
    children: [{
        path: '/maintenance-mode',
        name: 'global-maintenance-mode',
        component: () =>
            import ( /* webpackChunkName: "global-maintenance-mode" */ '@/views/error-page/MaintenanceMode.vue'),
    }, {
        path: '/403/:unique_id',
        name: '403',
        component: () =>
            import ( /* webpackChunkName: "403" */ '@/views/error-page/403.vue'),
    }, {
        path: '/413',
        name: '413',
        component: () =>
            import ( /* webpackChunkName: "413" */ '@/views/error-page/413.vue'),
    }, {
        path: '/500',
        name: '500',
        component: () =>
            import ( /* webpackChunkName: "500" */ '@/views/error-page/500.vue'),
    }, {
        path: '/access-denied',
        name: 'access-denied',
        component: () =>
            import ( /* webpackChunkName: "access-denied" */ '@/views/error-page/AccessDenied.vue'),
    }]
}