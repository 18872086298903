<template>
  <input
    :type="type"
    class="form-control"
    :class="{
      className,
    }"
    :placeholder="placeholder"
    :name="name"
    :id="getId"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    autocomplete="none"
  />
</template>

<script>
export default {
  name: "FormInput",
  props: {
    type: {
      type: String,
      default: "text",
    },
    className: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    modelValue: {
      default: "",
    },
  },

  computed: {
    getId() {
      if (this.id == "") {
        return this.name;
      }
      return this.id;
    },
  },
};
</script>
