export default {

    data() {
        return {
            formErrors: [],
            showFormValidationMsg: false,
        }
    },
    methods: {
        errorFor(field) {
            return typeof this.formErrors != 'undefined' && null !== this.formErrors && this.formErrors[field] ?
                this.formErrors[field] :
                null;
        }
    },
    beforeUnmount() {
        //Object.assign(this.$data, this.$options.data());
    },
}