export default {
    path: '/',
    name: 'pages-layout',
    component: require('@/views/pages/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_CUSTOMER_HOST
    },
    children: [{
        path: '/pages/:slug+',
        name: 'dynamic-page',
        component: () =>
            import ( /* webpackChunkName: "dynamic-page" */ '@/views/pages/Index.vue'),
        meta: {
            title: 'Internal Revenue Commission',
            requireAuth: false,
        },

    }]
}