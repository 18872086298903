export default {
    data(){
        return{
            refererLink: null,

        }
    },
    computed: {
        getMaxFileUploadSize() {
            return process.env.VUE_APP_MAX_FILE_UPLOAD_SIZE;
        }
    },

    created() {
        this.refererLink = document.referrer;
      },

    methods: {
        generateFormData(data, except_fields) {
            let formData = new FormData();
            for (var key in data) {
                if (typeof(data[key]) == 'object' && typeof(except_fields) != 'undefined' && !except_fields.includes(key)) {
                    formData.append(key, JSON.stringify(data[key]));
                } else {
                    if (typeof(data[key]) == 'object' && data[key].length > 0) {
                        for (var k in data[key]) {
                            formData.append('files[]', data[key][k]);
                        }

                    } else {
                        formData.append(key, data[key]);
                    }
                }
            }
            return formData;
        }
    }
}