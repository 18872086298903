export default [{
        path: '/myirc/tax-agent-centre/my-taxpayers',
        name: 'my-taxpayers',
        component: () =>
            import ( /* webpackChunkName: "my-taxpayers" */ '@/views/myirc/tax-agent-centre/my-taxpayers/Index.vue'),
        meta: {
            title: 'Manage my taxpayers',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkManageTaxpayersMaintenanceMode: true,
            permission: 'access_taxpayer_list'

        },
    }, {
        path: '/myirc/tax-agent-centre/my-taxpayers/historical-tlrs',
        name: 'my-historical-tlrs',
        component: () =>
            import ( /* webpackChunkName: "my-historical-tlrs" */ '@/views/myirc/tax-agent-centre/my-taxpayers/HistoricalTLR.vue'),
        meta: {
            title: 'Manage my historical tlrs',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkManageTaxpayersMaintenanceMode: true,
            permission: 'access_taxpayer_list'

        },
    }, {
        path: '/myirc/tax-agent-centre/my-taxpayers/create',
        name: 'add-a-new-taxpayer',
        component: () =>
            import ( /* webpackChunkName: "add-a-new-taxpayer" */ '@/views/myirc/tax-agent-centre/my-taxpayers/create/Index.vue'),
        meta: {
            title: 'Add taxpayers',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkManageTaxpayersMaintenanceMode: true,
            permission: 'access_taxpayer_list'
        },
    },
    {
        path: '/myirc/tax-agent-centre/my-taxpayers/create/invite-based',
        name: 'add-a-new-taxpayer-invite-based',
        component: () =>
            import ( /* webpackChunkName: "add-a-new-taxpayer-invite-based" */ '@/views/myirc/tax-agent-centre/my-taxpayers/create/invite-based/Index.vue'),
        meta: {
            title: 'Add taxpayers',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkManageTaxpayersMaintenanceMode: true,
            permission: 'access_taxpayer_list'
        },
    },
    {
        path: '/myirc/tax-agent-centre/my-taxpayers/create/loa-based',
        name: 'add-a-new-taxpayer-loa-based',
        component: () =>
            import ( /* webpackChunkName: "add-a-new-taxpayer-loa-based" */ '@/views/myirc/tax-agent-centre/my-taxpayers/create/loa-based/Index.vue'),
        meta: {
            title: 'Add taxpayers',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkManageTaxpayersMaintenanceMode: true,
            permission: 'access_taxpayer_list'
        },
    },
    {
        path: '/myirc/tax-agent-centre/my-taxpayers/create/loa-based/:unique_id',
        name: 'add-a-new-taxpayer-loa-based-step2',
        component: () =>
            import ( /* webpackChunkName: "add-a-new-taxpayer-loa-based-step2" */ '@/views/myirc/tax-agent-centre/my-taxpayers/create/loa-based/Index.vue'),
        meta: {
            title: 'Add taxpayers',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkManageTaxpayersMaintenanceMode: true,
            permission: 'access_taxpayer_list'
        },
    },
    {
        path: '/myirc/tax-agent-centre/my-taxpayers/:unique_id',
        name: 'view-my-taxpayer',
        component: () =>
            import ( /* webpackChunkName: "view-my-taxpayer" */ '@/views/myirc/tax-agent-centre/my-taxpayers/show/Index.vue'),
        meta: {
            title: 'View My Taxpayer',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkManageTaxpayersMaintenanceMode: true,
            permission: 'access_taxpayer_list'
        },
    },
    {
        path: '/myirc/tax-agent-centre/my-taxpayers/show/invite-based/:unique_id',
        name: 'invite-based-view-my-taxpayer',
        component: () =>
            import ( /* webpackChunkName: "invite-based-view-my-taxpayer" */ '@/views/myirc/tax-agent-centre/my-taxpayers/show/invite-based/Index.vue'),
        meta: {
            title: 'View My Taxpayer',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkManageTaxpayersMaintenanceMode: true,
            permission: 'access_taxpayer_list'
        },
    },
    {
        path: '/myirc/tax-agent-centre/my-taxpayers/show/loa-based/:unique_id',
        name: 'loa-based-view-my-taxpayer',
        component: () =>
            import ( /* webpackChunkName: "loa-based-view-my-taxpayer" */ '@/views/myirc/tax-agent-centre/my-taxpayers/show/loa-based/Index.vue'),
        meta: {
            title: 'View My Taxpayer',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkManageTaxpayersMaintenanceMode: true,
            permission: 'access_taxpayer_list'
        },
    },
    {
        path: '/myirc/tax-agent-centre/my-taxpayers/:unique_id/view',
        name: 'view-taxpayer-submission',
        component: () =>
            import ( /* webpackChunkName: "view-taxpayer-submission" */ '@/views/myirc/tax-agent-centre/my-taxpayers/show/Progress.vue'),
        meta: {
            title: 'View Taxpayer',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkManageTaxpayersMaintenanceMode: true,
            permission: 'access_taxpayer_list'
        },
    },
    // Manage Taxpayers Maintenance Mode Page
    {
        path: '/myirc/tax-agent-centre/my-taxpayers/maintenance-mode',
        name: 'manage-taxpayers-maintenance-mode-page',
        component: () =>
            import ( /* webpackChunkName: "manage-taxpayers-maintenance-mode-page" */ '@/views/myirc/tax-agent-centre/my-taxpayers/maintenance-mode/Index.vue'),
        meta: {
            title: 'Manage Taxpayers Maintenance Mode',
        },
    },
];