import { createStore } from 'vuex'
import { createRouter, createWebHistory } from "vue-router";

export default {

    methods: {
        isSecondaryAccount() {
            return this.$store.getters["user/isSecondaryAccount"];
        },
        doesAccountHavePermission(permission) {
            if (this.isSecondaryAccount()) {
                let permissionArr = this.$store.getters["user/secondaryAccountPermissions"];
                return permissionArr.includes(permission);
            } else {
                return true;
            }
        }
    }
}