export default {
    path: '/',
    name: 'news-layout',
    component: require('@/views/news/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_CUSTOMER_HOST
    },
    children: [{
            path: '/news/:category?',
            name: 'news',
            component: () =>
                import ( /* webpackChunkName: "news" */ '@/views/news/Index.vue'),
            meta: {
                title: 'News',
                requireAuth: false,
            },
        },
        {
            path: '/news/:category/:slug',
            name: 'news-detail',
            component: () =>
                import ( /* webpackChunkName: "news-detail" */ '@/views/news/Detail.vue'),
            meta: {
                title: 'News',
                requireAuth: false,
            },
        }
    ]
}