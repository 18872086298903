<template>
	<input type="file" class="form-control input-file" :class="{
		className,
		'is-invalid': element_error,
	}" :name="name" :id="id" :accept="accept" ref="file_upload" aria-describedby="inputGroupFileAddon05"
		aria-label="Upload" @change="changeEventFunction" :multiple="multiple" />
	<span v-if="selected_file != ''">{{ selected_file }}</span>
	<span v-if="selected_file == ''">{{ placeholder }}</span>
	<button class="btn" type="button" id="inputGroupFileAddon05" @click="chooseFile()">Select file</button>
</template>


<script>
export default {
	name: "FormInputFile",
	props: {
		className: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
		name: {
			type: String,
			default: "",
		},
		id: {
			type: String,
			default: "",
		},
		accept: {
			type: String,
			default: "*",
		},
		selected_file: {
			type: String,
			default: "*",
		},
		placeholder: {
			type: String,
			default: 'Please select file'
		},
		indexVal: Number,
		element_error: Boolean,
		changeEventFunction: Function,
		modelValue: String,
		multiple: Boolean
	},
	methods: {
		chooseFile() {
			this.$refs.file_upload.click();
		},

	}
};
</script>
