export default {
    path: '/',
    name: 'error-404-layout',
    component: require('@/views/error-404-page/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_ADMIN_HOST
    },
    children: [{
        path: '/error-page',
        name: 'error-page',
        component: () =>
            import ( /* webpackChunkName: "error-page" */ '@/views/error-404-page/404.vue'),
    }, {
        path: '/:pathMatch(.*)*',
        component: () =>
            import ( /* webpackChunkName: "error-page" */ '@/views/error-404-page/404.vue'),
    }]
}