import { createApp } from 'vue'
import App from './App.vue';
import VueProgressBar from "@aacassandra/vue3-progressbar";

const options = {
    color: "#359fa4",
    failedColor: "#874b4b",
    thickness: "5px",
    transition: {
        speed: "0.2s",
        opacity: "0.6s",
        termination: 300,
    },
    autoRevert: true,
    location: "top",
    inverse: false,
};

// Router
import router from "./router/index.js";

// Store
import store from './store'

import "bootstrap";

// Third Party
import thirdParty from '@/third-party'

// Custom Component
import customComponents from './custom-components/'


// Mixins
import ToastNotificationMixin from './mixins/toast-notifications.js'
import FormError from './mixins/form-error.js'
import StatusColor from './mixins/status-color.js'
import CustomMixin from './mixins/custom.js'
import SecondaryAccount from './mixins/secondary-account.js'
import helpers from './helpers.js'
import validations from './validations.js'

const app = createApp(App)

// Router
app.use(router);

// Store
app.use(store)

// Third Party
thirdParty(app)

// Custom
customComponents(app)

// Mixins
app.mixin(ToastNotificationMixin);
app.mixin(FormError);
app.mixin(StatusColor);
app.mixin(CustomMixin);
app.mixin(SecondaryAccount);

// Helper
const plugin = {
    install: (app) => {
        // Helper Function
        app.helpers = helpers
        app.config.globalProperties.$helpers = helpers
            // For Validation
        app.validations = validations
        app.config.globalProperties.$validations = validations
    }
}

app.use(plugin)
    .use(VueProgressBar, options);

app.mount('#app');