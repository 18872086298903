export default [{
        path: '/myirc/payments/make-a-payment',
        name: 'make-a-payment',
        component: () =>
            import ( /* webpackChunkName: "make-a-payment" */ '@/views/myirc/payments/make-a-payment/Index.vue'),
        meta: {
            title: 'Make a payment',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkPaymentMaintenanceMode: true,
            // permission: 'make_a_payment'
        },
    }, {
        path: '/myirc/payments/make-a-payment/taxpayer/:unique_id',
        name: 'make-a-payment-with-taxpayer',
        component: () =>
            import ( /* webpackChunkName: "make-a-payment-with-taxpayer" */ '@/views/myirc/payments/make-a-payment/Index.vue'),
        meta: {
            title: 'Make a payment',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkPaymentMaintenanceMode: true,
            // permission: 'make_a_payment'
        },
    }, {
        path: '/myirc/payments/make-a-payment/:category/:product',
        name: 'make-a-payment-with-category-and-type',
        component: () =>
            import ( /* webpackChunkName: "make-a-payment-with-category-and-type" */ '@/views/myirc/payments/make-a-payment/Index.vue'),
        meta: {
            title: 'Make a payment',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkPaymentMaintenanceMode: true,
            // permission: 'make_a_payment'
        },
    }, {
        path: '/myirc/payments/make-a-payment/basket',
        name: 'make-a-payment-basket',
        component: () =>
            import ( /* webpackChunkName: "make-a-payment-basket" */ '@/views/myirc/payments/make-a-payment/basket/Index.vue'),
        meta: {
            title: 'Make a payment',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkPaymentMaintenanceMode: true,
            permission: 'make_a_payment'
        },
    }, {
        path: '/myirc/payments/make-a-payment/billing',
        name: 'make-a-payment-billing',
        component: () =>
            import ( /* webpackChunkName: "make-a-payment-billing" */ '@/views/myirc/payments/make-a-payment/billing/Index.vue'),
        meta: {
            title: 'Make a payment',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkPaymentMaintenanceMode: true,
            permission: 'make_a_payment'
        },
    }, {
        path: '/myirc/payments/make-a-payment/confirm',
        name: 'make-a-payment-confirm',
        component: () =>
            import ( /* webpackChunkName: "make-a-payment-confirm" */ '@/views/myirc/payments/make-a-payment/confirm/Index.vue'),
        meta: {
            title: 'Make a payment',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkPaymentMaintenanceMode: true,
            permission: 'make_a_payment'
        },
    },  {
        path: '/myirc/payments/make-a-payment/success/:unique_id',
        name: 'payment-success',
        component: () =>
            import ( /* webpackChunkName: "payment-success" */ '@/views/myirc/payments/make-a-payment/Success.vue'),
        meta: {
            title: 'Payment Success',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkPaymentMaintenanceMode: true,
            permission: 'make_a_payment'
        },
    }, {
        path: '/myirc/payments/make-a-payment/failed/:unique_id',
        name: 'payment-failed',
        component: () =>
            import ( /* webpackChunkName: "payment-failed" */ '@/views/myirc/payments/make-a-payment/Failed.vue'),
        meta: {
            title: 'Payment Failed',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkPaymentMaintenanceMode: true,
            permission: 'make_a_payment'
        },
    },

    // My Payment Maintenance Mode Page
    {
        path: '/my-irc/make-a-payment/maintenance-mode',
        name: 'payment-maintenance-mode-page',
        component: () =>
            import ( /* webpackChunkName: "payment-maintenance-mode-page" */ '@/views/myirc/payments/make-a-payment/maintenance-mode/Index.vue'),
        meta: {
            title: 'Payment Maintenance Mode',
        },
    },

];