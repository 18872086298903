<template>
  <!-- Css -->
  <Css></Css>

  <Header></Header>
  <!-- Section -->
  <section>
    <router-view />
  </section>
  <Footer></Footer>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  name: "Error Layout",
  components: {
    Header: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "error-header" */ "@/layouts/error/Header.vue"
      )
    ),
    Footer: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "error-footer" */ "@/layouts/error/Footer.vue"
      )
    ),

    Css: defineAsyncComponent(() =>
      import(/* webpackChunkName: "error-page-css" */ "@/layouts/app/header/Css.vue")
    ),

    /*Header: require("@/layouts/error/Header.vue").default,
    Footer: require("@/layouts/error/Footer.vue").default,*/
  },
};
</script>
