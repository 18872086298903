<template>
  <button
    type="submit"
    class="btn irc-btn btn-dark-grey d-flex btn-small float-end"
  >
    <template v-if="loading">
      <span
        v-if="loading"
        class="
          spinner-border spinner-border-sm
          align-self-center align-middle
          me-2
        "
      ></span>
      Please wait...
    </template>
	 <template v-else>
    <i class="fal fa-search left-icon align-self-center"></i> Search
	 </template>
  </button>
  
</template>

<script>
export default {
  name: "SearchButton",
  props: {
    loading: Boolean,   
  },
};
</script>
