export default {
    data() {
        return {
            breadcrumb_arr: '',
            myIRCMenuClass: ''
        }
    },
    methods: {
        setBreadcrumb(arr) {
            this.breadcrumb_arr = arr;
        },

        overlayClick() {
            this.myIRCMenuClass = "";
        },

        myIRCMenuBtnClick() {
            if (this.myIRCMenuClass == 'open') {
                this.myIRCMenuClass = '';
            } else {
                this.myIRCMenuClass = 'open';
            }
        }
    }
}