import { createRouter, createWebHistory } from "vue-router";
import axios from 'axios'
import store from "@/store/";

import ls from 'localstorage-slim';

// enable global encryption
ls.config.encrypt = true;

let myVarInterval = null;

const routes = [

    // Home Routes
    require('./home').default,

    // Pitaa
    // require('./pitaa').default,

    // Register Routes
    require('./register').default,

     // 2FA Reauthentication
     require('./2fa-reauthentication').default,

    // Auth Routes
    require('./auth').default,

    // Auth Routes
    require('./myirc').default,

    // Pages
    require('./pages').default,

    // Support Centre
    require('./support-centre').default,

    // Faqs
    require('./faqs').default,

    // Key Tax Dates
    require('./key-tax-dates').default,

    // News
    require('./news').default,

    // Search Page
    require('./search').default,

    // Static Pages
    require('./static-pages').default,

    // MyIRC Update Pages
    require('./myirc-updates').default,


    // Sitemap Page
    require('./sitemap').default,

    // secondary-account invitation
    require('./secondary-account-invitations').default,

    // make a payment online
    require('./make-a-payment-online').default,

    // Tax Tools
    require('./tax-tools').default,

    // Taxpayer Information Kit
    require('./taxpayer-information-kit').default,

    // Error Routes
    require('./error').default,

    // unsubscribe Routes
    require('./unsubscribe').default,

    // Error 404 Routes
    require('./error-page').default,
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

async function checkLoginExpiryFunc() {
    var login_expire_at = ls.get("login_expire_at");

    if (login_expire_at !== null && new Date().getTime() > login_expire_at) {
        store.commit("user/logout");
        clearTimeout(myVarInterval);
        router.push({
            name: "login",
            params: { type: "logout" },
        });
    }
}


async function checkGloballMaintenanceModeFunc() {

    var website_access_token = store.getters["user/getWebsiteAccessToken"]
        //alert(website_access_token);



    var retVal = {
        'status': 200,
        'value': false,
    };

    await axios
        .post("/api/maintenance-mode", {
            mode_type: 'Website',
            token: website_access_token
        })
        .then(() => {

        })
        .catch((error) => {
            if (error.response && 520 == error.response.status) {
                retVal = {
                    'status': error.response.status,
                    'value': true,
                };
            }
            if (error.response && 510 == error.response.status) {
                retVal = {
                    'status': error.response.status,
                    'value': true,
                };
            }
        });

    return retVal;


}


async function checkNewRegistrationMaintenanceModeFunc() {
    var retVal = '';
    await axios
        .post("/api/maintenance-mode", {
            mode_type: 'New Customer'
        })
        .then(() => {
            retVal = false;
        })
        .catch((error) => {
            if (error.response && 510 == error.response.status) {
                retVal = true;
            }
        });

    return retVal;
}

async function checkMyIRCMaintenanceModeFunc() {
    var retVal = '';
    await axios
        .post("/api/maintenance-mode", {
            mode_type: 'MyIRC'
        })
        .then(() => {
            retVal = false;
        })
        .catch((error) => {
            if (error.response && 510 == error.response.status) {
                retVal = true;
            }
        });

    return retVal;
}


async function checkPaymentMaintenanceModeFunc() {
    var retVal = '';
    await axios
        .post("/api/maintenance-mode", {
            mode_type: 'Payment'
        })
        .then(() => {
            retVal = false;
        })
        .catch((error) => {
            if (error.response && 510 == error.response.status) {
                retVal = true;
            }
        });

    return retVal;
}

async function checkLodgementMaintenanceModeFunc(customer_id) {

    var retVal = '';
    await axios
        .post("/api/maintenance-mode", {
            mode_type: 'Lodgements',
            customer_id:customer_id,
        })
        .then(() => {
            retVal = false;
        })
        .catch((error) => {
            if (error.response && 510 == error.response.status) {
                retVal = true;
            }
        });

    return retVal;
}

async function checkManageTaxpayersMaintenanceModeFunc() {
    var retVal = '';
    await axios
        .post("/api/maintenance-mode", {
            mode_type: 'Manage Taxpayers'
        })
        .then(() => {
            retVal = false;
        })
        .catch((error) => {
            if (error.response && 510 == error.response.status) {
                retVal = true;
            }
        });

    return retVal;
}

async function userLogout() {
    await axios
        .post("/api/logout")
        .then((response) => {
            store.commit("user/logout");
        })
        .catch((error) => {
            store.commit("user/logout");
        });
}

router.afterEach((to, from) => {
    if (to.fullPath !== from.fullPath) {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }
});

router.beforeEach(async(to, from, next) => {

    document.title = to.meta.title ? `${ to.meta.title } | ${ process.env.VUE_APP_APP_NAME }` : '';

    if (to.path == '/logout') {
        const customer_item = store.getters["user/getUserItem"];
        if (customer_item != null) {
            await userLogout();
        }
    }

    // Check for Global Maintenance Mode [Start]

    let ignore_paths = [
        '/admin-access',
        '/maintenance-mode'       
    ];


    if (!ignore_paths.includes(to.path)) {

        let globalMaintenanceMode = await checkGloballMaintenanceModeFunc();

        if (globalMaintenanceMode.value === true && globalMaintenanceMode.status === 510) {
            next({ name: 'global-maintenance-mode' });
            return;
        }
    }
    // Check for Global Maintenance Mode [End]   



    var isAuthenticated = store.getters['user/isAuthenticated'];

    // Check for Require Auth
    if (to.matched.some(record => record.meta.requireAuth)) {

        if (isAuthenticated == true) {

            clearTimeout(myVarInterval);
            await checkLoginExpiryFunc();
            myVarInterval = setInterval(() => {
                checkLoginExpiryFunc();
            }, 1000);



            if (to.matched.some(record => record.meta.checkSecondaryAccount)) {
                let isSecondaryAccount = store.getters["user/isSecondaryAccount"];
                if (isSecondaryAccount) {
                    router.push({ name: "dashboard" });
                }
            }


            if (to.matched.some(record => record.meta.permission)) {
                let isSecondaryAccount = store.getters["user/isSecondaryAccount"];

                if (isSecondaryAccount) {
                    let permissionArr = store.getters["user/secondaryAccountPermissions"];
                    if (!permissionArr.includes(to.meta.permission)) {
                        if (to.meta.permission == 'make_a_payment') {
                            router.push({ name: "make-a-payment" });
                        } else if (to.meta.permission == 'manage_lodgement') {
                            router.push({ name: "my-lodgement" });
                        } else if (to.meta.permission == 'manage_support_ticket') {
                            router.push({ name: "support-centre" });
                        } else {
                            router.push({ name: "access-denied" });
                        }

                    }
                }
            }

        }


        if (!isAuthenticated) {
            next({
                name: 'login',
                query: { redirect: to.path }
            });
            return;
        }


        var isGlobal2faAuthenticated = store.getters['user/getRequireGlobal2fa'];
        if (to.path === '/2fa') {
            var customer_item = store.getters['user/getUserItem'];            
            if(eval(customer_item.user_two_factor_authentication_status) === false){
                next({ name: 'dashboard' });
                return;
            }else if (isGlobal2faAuthenticated == false) {
                next({ name: 'dashboard' });
                return;
            }
        }
        
        if (isGlobal2faAuthenticated == true) {
            var is2faAuthenticated = store.getters['user/getIs2faAuthenticated'];
            var customer_item = store.getters['user/getUserItem'];

            if (to.matched.some(record => record.meta.allowNon2FAOnly) && is2faAuthenticated) {
                next({ name: 'dashboard' });
                return;
            } else if (!is2faAuthenticated && to.path === '/2fa') {
                next();
                return;
            } else if (eval(customer_item.user_two_factor_authentication_status) == true){
                if (!is2faAuthenticated || is2faAuthenticated == null) {
                    next({
                        name: '2fa',
                        query: { redirect: to.path }
                    });
                    return;
                }else{
                    next();
                    return;
                }
            } else{
                next();
                return;
            }
        }


    } else if (to.matched.some(record => record.meta.allowGuestOnly) && isAuthenticated) {
        next({ name: 'dashboard' })
        return;
    }


    // Check for New Rigistration Maintenance Mode [Start]
    if (typeof to.meta.checkNewRegistrationMaintenanceMode !== 'undefined') {
        const newRegistrationMaintenanceMode = await checkNewRegistrationMaintenanceModeFunc();
        if (newRegistrationMaintenanceMode === true) {
            next({ name: 'register-maintenance-mode-page' });
            return;
        }
    }
    // Check for New Rigistration Maintenance Mode [End]


    // Check For MyIRC MaintenanceMode [Start]
    if (typeof to.meta.checkMyIRCMaintenanceMode !== 'undefined') {
        const myIRCMaintenanceMode = await checkMyIRCMaintenanceModeFunc();
        if (myIRCMaintenanceMode === true) {
            next({ name: 'my-irc-maintenance-mode-page' });
            return;
        }
    }
    // Check For MyIRC MaintenanceMode [End]

    // Check For Payment MaintenanceMode [Start]

    if (typeof to.meta.checkPaymentMaintenanceMode !== 'undefined') {
        const paymentMaintenanceMode = await checkPaymentMaintenanceModeFunc();
        if (paymentMaintenanceMode === true) {
            next({ name: 'payment-maintenance-mode-page' });
            return;
        }
    }
    // Check For Payment MaintenanceMode [End]


    // Check For Lodgement MaintenanceMode [Start]

    if (typeof to.meta.checkLodgementMaintenanceMode !== 'undefined') {
        const customer_item = store.getters["user/getUserItem"];            
        const lodgementMaintenanceMode = await checkLodgementMaintenanceModeFunc(customer_item.id);
        if (lodgementMaintenanceMode === true) {
            next({ name: 'lodgement-maintenance-mode-page' });
            return;
        }
    }
    // Check For Lodgement MaintenanceMode [End]

    // Check For Manage Taxpayers MaintenanceMode [Start]

    if (typeof to.meta.checkManageTaxpayersMaintenanceMode !== 'undefined') {
        const manageTaxpayersMaintenanceMode = await checkManageTaxpayersMaintenanceModeFunc();
        if (manageTaxpayersMaintenanceMode === true) {
            next({ name: 'manage-taxpayers-maintenance-mode-page' });
            return;
        }
    }
    // Check For Manage Taxpayers MaintenanceMode [End]

    next();
});


export default router;