<template>
  <textarea
    class="form-control"
    :class="{
      className,
      'is-invalid': element_error,
    }"
    :placeholder="placeholder"
    :name="name"
    :id="id"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    autocomplete="none"
  >
  </textarea>
</template>

<script>
export default {
  name: "FormTextArea",
  props: {
    className: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    element_error: Boolean,
    modelValue: String,
  },
};
</script>
