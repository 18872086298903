<template>
  <Select2
    :modelValue="String(modelValue)"
    :options="getSelect2OptionList"
    class="form-select2"
    :placeholder="placeholder"
    :class="{
      className,
    }"
    :name="name"
    :id="getId"
    @select="mySelectEvent($event)"
    autocomplete="off"
  />
</template>

<script>
import Select2 from "vue3-select2-component";

export default {
  name: "FormSelect2",
  components: {
    Select2,
  },
  props: {
    className: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Select option",
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    show_placeholder: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      default: "",
    },
    options: Object,
    changeFunc: {
      type: Function,
      default: () => {},
    },
    sort_order: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    mySelectEvent({ id, text }) {
      this.$emit("update:modelValue", id);
      this.changeFunc();
    },
  },

  computed: {
    getId() {
      if (this.id == "") {
        return this.name;
      }
      return this.id;
    },
    getModelValue() {
      return String(this.modelValue);
    },
    getSelect2OptionList() {
      let retArr = [];
      for (let key in this.options) {
        if (key !== null) {
          if (this.sort_order) {
            retArr.push({
              id: this.options[key],
              text: key,
            });
          } else {
            retArr.push({
              id: key,
              text: this.options[key],
            });
          }
        }
      }
      return retArr;
    },
  },
};
</script>
