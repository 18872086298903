// Store
import store from "@/store/";

// Axios
import axios from 'axios'
import VueAxios from 'vue-axios'
import { Skeletor } from 'vue-skeletor';
import { createI18n } from 'vue-i18n';

// enable global encryption
import ls from 'localstorage-slim';

ls.config.encrypt = true;

try {
  var token = ls.get("auth_token");
} catch (err) {
  ls.clear();
}

// Ready translated locale messages
const messages = {
    en: {
        message_notification: 'no notifications | {count} notification | {count} notifications'
    }
}

const i18n = createI18n({
    messages, // set locale messages
});

axios.defaults.headers.common = {
    "Authorization": `Bearer ${ls.get('auth_token')}`,
    "accept": `application/json`,
};

axios.interceptors.response.use(
    function(successRes) {
        var isAuthenticated = store.getters['user/isAuthenticated'];
        if (isAuthenticated == true) {
            const now = new Date();

            let cur_time = now.getTime();
            let login_expire_at = ls.get('login_expire_at');
            if (cur_time >= login_expire_at) {
                store.commit("user/logout");
            } else {
                store.commit("user/updateLoginExpiry");
            }
        }
        return successRes;
    },
    function(error) {
        return Promise.reject(error);
    }
);

// Sweet Alert 2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// Toast Notification
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

// CkEditor
import CKEditor from '@ckeditor/ckeditor5-vue';

// FancyBox
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

// Multile Select
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css';

// Tooltip
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css' // optional for styling
import 'tippy.js/animations/shift-away.css'

// Pagination
import Pagination from 'v-pagination-3';

// Clipboard
import { VueClipboard } from '@soerenmartius/vue3-clipboard'

// Input Mask
import Maska from 'maska'

// Datepicker
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

// Vue Head
import { createHead } from '@vueuse/head'
const head = createHead()

// Google Map
import VueGoogleMaps from '@fawmi/vue-google-maps'

// Print
import print from 'vue3-print-nb'

import VueGtag from "vue-gtag";
import MasonryWall from '@yeger/vue-masonry-wall';

import VOtpInput from "vue3-otp-input";

export default app => {

    // Sweet Alert 2
    app.use(VueSweetalert2);

    // Toast Notification
    app.use(VueToast);

    // CkEditor
    app.use(CKEditor);

    // Multile Select
    app.component('Multiselect', Multiselect);

    // Skeletor Loading
    app.component(Skeletor.name, Skeletor);

    // Axios
    app.use(VueAxios, axios);

    // Pagination
    app.component('pagination', Pagination);

    // Input Mask
    app.use(Maska)

    app.use(
        VueTippy,
        // optional
        {
            directive: 'tippy', // => v-tippy
            component: 'tippy', // => <tippy/>
            componentSingleton: 'tippy-singleton', // => <tippy-singleton/>
            defaultProps: {
                placement: 'top',
                animation: 'shift-away'
            }
        }
    )

    // Clipboard
    app.use(VueClipboard)

    // Datepicker    
    app.component('flat-pickr', flatPickr);

    // Head
    app.use(head);

    // Google Map
    app.use(VueGoogleMaps, {
        load: {
            key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
        },
        autobindAllEvents: true,
    });

    // Print
    app.use(print)

    app.use(VueGtag, {
        config: {
            id: process.env.VUE_APP_GOOGLE_ANALYTICS_KEY
        }
    });

    app.use(MasonryWall);

    app.use(i18n);


    app.component('v-otp-input', VOtpInput);
}