export default {
    path: '/',
    name: 'myirc-updates-layout',
    component: require('@/views/myirc-updates/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_ADMIN_HOST
    },
    children: [{
        path: '/myirc-updates',
        name: 'myirc-updates',
        component: () =>
            import ( /* webpackChunkName: "myirc-updates" */ '@/views/myirc-updates/Index.vue'),
    },
    {
        path: '/myirc-updates/:slug',
        name: 'show-myirc-updates',
        component: () =>
            import ( /* webpackChunkName: "show-myirc-updates" */ '@/views/myirc-updates/Detail.vue'),
    }]
}