export default {
    path: '/',
    name: 'static-page-layout',
    component: require('@/views/static-pages/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_ADMIN_HOST
    },
    children: [{
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () =>
            import ( /* webpackChunkName: "privacy-policy" */ '@/views/static-pages/PrivacyPolicy.vue'),
    }, {
        path: '/cookie-policy',
        name: 'cookie-policy',
        component: () =>
            import ( /* webpackChunkName: "cookie-policy" */ '@/views/static-pages/CookiePolicy.vue'),
    }, {
        path: '/disclaimer',
        name: 'disclaimer',
        component: () =>
            import ( /* webpackChunkName: "disclaimer" */ '@/views/static-pages/Disclaimer.vue'),
    }, {
        path: '/accessibility',
        name: 'accessibility',
        component: () =>
            import ( /* webpackChunkName: "accessibility" */ '@/views/static-pages/Accessibility.vue'),
    }]
}