export default [{
        path: '/myirc/my-account/update-my-billing-information',
        name: 'update-my-billing-information',
        component: () =>
            import ( /* webpackChunkName: "update-my-billing-information" */ '@/views/myirc/my-account/UpdateBillingInformation.vue'),
        meta: {
            title: 'Update my billing information',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    }, {
        path: '/myirc/my-account/update-my-appointed-nominee',
        name: 'update-my-appointed-nominee',
        component: require('@/views/myirc/my-account/UpdateAppointedNominee.vue').default,
        meta: {
            title: 'Update my Appointed Nominee',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    }, {
        path: '/myirc/my-account/my-nominated-tax-agent',
        name: 'my-nominated-tax-agent',
        component: () =>
            import ( /* webpackChunkName: "my-nominated-tax-agent" */ '@/views/myirc/my-account/my-nominated-tax-agent/Index.vue'),
        meta: {
            title: 'My Nominated Tax Agent',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    },
    {
        path: '/myirc/my-account/my-nominated-tax-agents',
        name: 'my-nominated-tax-agents',
        component: () =>
            import ( /* webpackChunkName: "my-nominated-tax-agents" */ '@/views/myirc/my-account/my-nominated-tax-agents/Index.vue'),
        meta: {
            title: 'My Nominated Tax Agents',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    }, {
        path: '/myirc/my-account/my-nominated-tax-agents/:unique_id',
        name: 'view-nominated-tax-agent',
        component: () =>
            import ( /* webpackChunkName: "view-nominated-tax-agent" */ '@/views/myirc/my-account/my-nominated-tax-agents/Show.vue'),
        meta: {
            title: 'View Nominated Tax Agent',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    }, {
        path: '/myirc/my-account/update-my-partnership-information',
        name: 'update-my-partnership-information',
        component: () =>
            import ( /* webpackChunkName: "update-my-partnership-information" */ '@/views/myirc/my-account/UpdatePartner.vue'),
        meta: {
            title: 'Update my partnership information',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    }, {
        path: '/myirc/my-account/update-my-director-information',
        name: 'update-my-director-information',
        component: () =>
            import ( /* webpackChunkName: "update-my-director-information" */ '@/views/myirc/my-account/UpdateDirector.vue'),
        meta: {
            title: 'Update my director information',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    }, {
        path: '/myirc/my-account/my-taxpayer-information',
        name: 'my-taxpayer-information',
        component: () =>
            import ( /* webpackChunkName: "my-taxpayer-information" */ '@/views/myirc/my-account/MyTaxpayerInformation.vue'),
        meta: {
            title: 'My taxpayer information',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    }, {
        path: '/myirc/my-account/my-tax-agent-information',
        name: 'my-tax-agent-information',
        component: () =>
            import ( /* webpackChunkName: "my-tax-agent-information" */ '@/views/myirc/my-account/MyTaxAgentInformation.vue'),
        meta: {
            title: 'My Tax Agent information',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    }, {
        path: '/myirc/my-account/my-account-setting',
        name: 'my-account-setting',
        component: () =>
            import ( /* webpackChunkName: "my-account-setting" */ '@/views/myirc/my-account/account-settings/Index.vue'),
        meta: {
            title: 'My Account Setting',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    }, {
        path: '/myirc/my-account/notification-settings',
        name: 'notification-settings',
        component: () =>
            import ( /* webpackChunkName: "notification-settings" */ '@/views/myirc/my-account/notification-settings/Index.vue'),
        meta: {
            title: 'Notification Settings',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    },
    // My Nominated Tax Agent Activities
    {
        path: '/myirc/my-account/my-nominated-tax-agent-activities',
        name: 'my-nominated-tax-agent-activities',
        component: () =>
            import ( /* webpackChunkName: "my-nominated-tax-agent-activities" */ '@/views/myirc/my-account/my-nominated-tax-agent-activities/Index.vue'),
        meta: {
            title: 'My Nominated Tax Agent Activities',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    },
    {
        path: '/myirc/my-account/my-nominated-tax-agent-activities/payments/:unique_id',
        name: 'view-nominated-tax-agent-activity-payment',
        component: () =>
            import ( /* webpackChunkName: "view-nominated-tax-agent-activity-payment" */ '@/views/myirc/my-account/my-nominated-tax-agent-activities/payments/Transaction.vue'),
        meta: {
            title: 'My Nominated Tax Agent Payment',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    },
    {
        path: '/myirc/my-account/my-nominated-tax-agent-activities/lodgements/:unique_id',
        name: 'view-nominated-tax-agent-activity-lodgement',
        component: () =>
            import ( /* webpackChunkName: "view-nominated-tax-agent-activity-lodgement" */ '@/views/myirc/my-account/my-nominated-tax-agent-activities/lodgements/Show.vue'),
        meta: {
            title: 'My Nominated Tax Agent Lodgement',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
        },
    }
];