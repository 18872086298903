<template>
	<button
		:type="buttonType"
		ref="submitButton"
		:id="buttonId"
		class="btn"
		:disabled="loading"
		:class="buttonClass"
	>
		<span v-if="!loading">{{ buttonTitle }}</span>
		<i v-if="buttonIcon != '' && !loading" :class="buttonIconClass"></i>
		<span v-if="loading">
			Please wait...
		</span>
		<span
			v-if="loading"
			class="
				spinner-border spinner-border-sm
				align-self-center align-middle
				ms-2
			"
		></span>
	</button>
</template>

<script>
export default {
	name: "FormButton",
	props: {
		loading: Boolean,
		buttonType: {
			type: String,
			default: "button",
		},
		buttonClass: {
			type: String,
			default: "irc-btn btn-yellow d-flex",
		},
		buttonTitle: {
			type: String,
			default: "Submit",
		},
		buttonId: {
			type: String,
		},
		buttonIcon: {
			type: String,
		},
		buttonIconClass: {
			type: String,
		},
	},
};
</script>
