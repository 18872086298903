export default {
  path: "/",
  name: "taxpayer-information-kit-layout",
  component: require("@/views/taxpayer-information-kit/Layout.vue").default,
  meta: {
    domains: process.env.VUE_APP_CUSTOMER_HOST,
  },
  children: [
    {
      path: "/taxpayer-information-kit/:slug?",
      name: "taxpayer-information-kit",
      component: () =>
        import(
          /* webpackChunkName: "taxpayer-information-kit" */ "@/views/taxpayer-information-kit/Index.vue"
        ),
      meta: {
        title: "Taxpayer Information Kit",
        requireAuth: false,
      },
    }
  ],
};
