import { createStore } from 'vuex'
//import createPersistedState from "vuex-persistedstate";

import user from './user'

const store = createStore({
    modules: {
        user
    },
    //  plugins: [createPersistedState()],

})

export function useStore() {
    return store
}

export default store