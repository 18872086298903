export default {
    path: '/',
    name: 'unsubscribe-layout',
    component: require('@/views/unsubscribe/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_ADMIN_HOST
    },
    children: [{
        path: '/mailing-list/unsubscribe/:token',
        name: 'unsubscribe',
        component: () =>
            import ( /* webpackChunkName: "unsubscribe" */ '@/views/unsubscribe/Index.vue'),
    }]
}