export default {
    path: '/',
    name: 'tax-tools-layout',
    component: require('@/views/tax-tools/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_CUSTOMER_HOST
    },
    children: [{
            path: '/tax-tools',
            name: 'tax-tools',
            component: () =>
                import ( /* webpackChunkName: "tax-tools" */ '@/views/tax-tools/Index.vue'),
            meta: {
                title: 'Tax Tools',
                requireAuth: false,
            },
        },
        // COC
        {
            path: '/tax-tools/certificate-of-compliance-lookup',
            name: 'non-customer-certificate-of-compliance-lookup',
            component: () =>
                import ( /* webpackChunkName: "non-customer-certificate-of-compliance-lookup" */ '@/views/tax-tools/coc/Index.vue'),
            meta: {
                title: 'Certificate of Compliance Lookup',
                requireAuth: false,
            },
        },

        // TIN Validity Lookup
        {
            path: '/tax-tools/tin-validity-lookup',
            name: 'non-customer-tin-validity-lookup',
            component: () =>
                import ( /* webpackChunkName: "non-customer-tin-validity-lookup" */ '@/views/tax-tools/tin-validity-lookup/Index.vue'),
            meta: {
                title: 'TIN Validity Lookup',
                requireAuth: false,
            },
        },
        // Swt Calculator
        {
            path: '/tax-tools/salary-or-wages-tax-calculator',
            name: 'salary-or-wages-tax-calculator',
            component: () =>
                import ( /* webpackChunkName: "salary-or-wages-tax-calculator" */ '@/views/tax-tools/swt-calculator/Index.vue'),
            meta: {
                title: 'Salary or Wages Tax Calculator',
                requireAuth: false,
            },
        }

    ]
}