export default [{
    path: '/myirc/tax-agent-centre/secondary-accounts-activities',
    name: 'secondary-accounts-activities',
    component: () =>
        import ( /* webpackChunkName: "secondary-accounts-activities" */ '@/views/myirc/tax-agent-centre/secondary-accounts-activities/Index.vue'),
    meta: {
        title: 'Manage my secondary account activities',
        requireAuth: true,
        checkMyIRCMaintenanceMode: true,
    },
}, {
    path: '/myirc/tax-agent-centre/secondary-accounts-activities/payment/:unique_id',
    name: 'view-secondary-accounts-activity-payment',
    component: () =>
        import ( /* webpackChunkName: "view-secondary-accounts-activity-payment" */ '@/views/myirc/tax-agent-centre/secondary-accounts-activities/payments/Show.vue'),
    meta: {
        title: 'View my secondary account activity Payment',
        requireAuth: true,
        checkMyIRCMaintenanceMode: true,
    },
}, {
    path: '/myirc/tax-agent-centre/my-secondary-account-activities/lodgement/:unique_id',
    name: 'view-secondary-accounts-activity-lodgement',
    component: () =>
        import ( /* webpackChunkName: "view-secondary-accounts-activity-lodgement" */ '@/views/myirc/tax-agent-centre/secondary-accounts-activities/lodgements/Show.vue'),
    meta: {
        title: 'View my secondary account activity Lodgement',
        requireAuth: true,
        checkMyIRCMaintenanceMode: true,
    },
}];