<template>
  <router-link :to="to" :class="btn_class" @click="iconChange(btn_key)">
    <template v-if="icon_align == 'left'">
      <i
        :class="[
          icon_class,
          link_icon == '' ? icon : '',
          link_icon == btn_key ? 'spinner-border spinner-border-sm' : '',
        ]"
      ></i>
      <template v-if="title != ''"> <span v-html="title"></span> </template>
    </template>
    <template v-else>
      <template v-if="title != ''"><span v-html="title"></span></template>
      <i
        :class="[
          icon_class,
          link_icon == '' ? icon : '',
          link_icon == btn_key ? 'spinner-border spinner-border-sm' : '',
        ]"
      ></i>
    </template>
  </router-link>
</template>

<script>
export default {
  name: "Router Link Button",
  props: {
    title: {
      type: String,
      default: "",
    },
    btn_key: {
      type: String,
      default: "",
    },
    to: {
      default: "",
    },
    btn_class: {
      type: String,
      default: "",
    },
    icon_class: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    icon_align: {
      type: String,
      default: "right",
    },
  },

  data() {
    return {
      link_icon: "",
    };
  },

  mounted() {
    this.link_icon = "";
  },
  unmounted() {
    this.link_icon = "";
  },

  methods: {
    iconChange(btn_key) {
      this.link_icon = btn_key;

      setTimeout(() => {
        this.link_icon = '';
      }, 2500);
    },
  },
};
</script>
