export default [{
        path: '/myirc/identity-verification',
        name: 'identity-verification',
        component: () =>
            import ( /* webpackChunkName: "identity-verification" */ '@/views/myirc/identity-verification/Index.vue'),
        meta: {
            title: 'Identity Verification',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkSecondaryAccount: true,
        },
    },
    {
        path: '/myirc/identity-verification/start-verification-process',
        name: 'start-identity-verification-process',
        component: () =>
            import ( /* webpackChunkName: "start-identity-verification-process" */ '@/views/myirc/identity-verification/start-verification-process/Index.vue'),
        meta: {
            title: 'Start Identity Verification Process',
            requireAuth: true,
            checkMyIRCMaintenanceMode: true,
            checkSecondaryAccount: true,
        },
    }
];