export default {
    data() {
        return {
            status_color_list: {
                'Active': 'primary',
                'Inactive': 'warning',
                'Draft': 'info',
            }
        }
    },
    methods: {
        getStatusColor(status) {
            return this.status_color_list[status];
        }
    }
}