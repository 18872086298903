export default {
    path: '/',
    name: 'invitations-layout',
    component: require('@/views/secondary-account-invitations/Layout.vue').default,
    meta: {
        domains: process.env.VUE_APP_CUSTOMER_HOST
    },
    children: [{
        path: '/secondary-account/invitation/:token',
        name: 'secondary-account/invitation',
        component: () =>
            import ( /* webpackChunkName: "secondary-account-invitation" */ '@/views/secondary-account-invitations/Index.vue'),
        meta: {
            title: 'Finish setup of your myIRC Account',
            requireAuth: false,
        },
    }]
}