<template>
  <!-- Hero Sec -->
  <div class="hero_sec inner_page_banner">
    <div class="container">
      <div class="banner-content">
        <h1>You have successfully logged out of myIRC</h1>
        <h2>
          Secure and convenient management of your tax obligations from the
          comfort of your home, office or while on the move.
        </h2>
      </div>
    </div>
  </div>

  <LoginForm></LoginForm>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  name: "Logout",
  components: {
    LoginForm: defineAsyncComponent(() =>
      import(/* webpackChunkName: "login-form" */ "@/views/auth/LoginForm.vue")
    ),
    /*LoginForm: require("@/views/auth/LoginForm.vue").default,*/
  },
};
</script>
