<template>
	<div
		class="alert alert-danger fw-400 text-danger"
		:class="margin_bottom"
		v-if="Object.keys(errors).length > 0 || formValidationMsg"
	>
		<div class="d-flex alert-msg">
			<i class="fal fa-exclamation-circle align-self-center"></i>
			<div class="d-flex fw-normal align-self-center">
				We are unable to proceed. Ensure that all compulsory fields (marked with
				an asterisk*) are filled in with valid entries.
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Form Validation Error Message",
	props: {
		errors: Object,
		formValidationMsg: Boolean,
		margin_bottom: {
			type: String,
			default: "mb-5",
		},
	},
};
</script>
